<template>
  <div class="container-fluid">
    <!-- spinners container-->
    <div class="spinners-container">
      <!-- spinner #3 -->
      <div class="spinner-block">
        <!-- spinner #3 effect -->
        <div class="spinner-eff spinner-eff-3">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
          <div class="circle circle-3"></div>
        </div>
        loading ...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped lang="scss">
@import "loader.scss";
</style>
