<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-space">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-3">
                  <div
                    class="nav flex-column nav-pills nav-secondary nav-pills-no-bd nav-pills-icons"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active show"
                      id="v-pills-buy-tab-icons"
                      data-toggle="pill"
                      href="#v-pills-buy-icons"
                      role="tab"
                      aria-controls="v-pills-buy-icons"
                      aria-selected="false"
                    >
                      <i class="icon-user"></i>
                      Mon compte
                    </a>
                    <a
                      class="nav-link"
                      id="v-pils-profile-tab-icons"
                      data-toggle="pill"
                      href="#v-pills-profile-icons"
                      role="tab"
                      aria-controls="v-pills-profile-icons"
                      aria-selected="false"
                    >
                      <i class="icon-globe"></i>
                      Présence en ligne
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-quality-tab-icons"
                      data-toggle="pill"
                      href="#v-pills-quality-icons"
                      role="tab"
                      aria-controls="v-pills-quality-icons"
                      aria-selected="false"
                    >
                      <i class="icon-information"></i>
                      A propos de l'application
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-9">
                  <div class="tab-content" id="v-pills-tabContent">
                    <p class="text-danger">{{ errorMessage2 }}</p>
                    <div
                      class="tab-pane fade"
                      id="v-pills-profile-icons"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab-icons"
                    >
                      <h5
                        class="mt-3"
                        style="
                          font-size: 16px;
                          font-weight: bold;
                          padding-left: 18px;
                        "
                      >
                        <i class="icon-globe"></i> Présence en ligne
                      </h5>
                      <hr />

                      <div
                        class="form-group"
                        v-for="(social, index) in socials"
                        :key="index"
                      >
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i :class="social.icon"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="social.name"
                            :aria-label="social.name"
                            v-model="social.value"
                          />
                        </div>
                      </div>
                      <div style="margin-right: 11px">
                        <button
                          class="btn btn-success float-right"
                          @click="saveSocialsSettings()"
                        >
                          Enregistrer maintenant
                        </button>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade active show"
                      id="v-pills-buy-icons"
                      role="tabpanel"
                      aria-labelledby="v-pills-buy-tab-icons"
                    >
                      <h5
                        class="mt-3"
                        style="
                          font-size: 16px;
                          font-weight: bold;
                          padding-left: 18px;
                        "
                      >
                        <i class="icon-user"></i> Informations personnelles
                      </h5>
                      <hr />
                      <div class="col-md-6 col-lg-12">
                        <p class="text-success">{{ successMessage }}</p>
                        <p class="text-danger">{{ errorMessage }}</p>
                        <form
                          @submit.prevent="updateProfile"
                          method="POST"
                          action="#"
                        >
                          <div class="form-group">
                            <label for="username">Nom d'utilisateur*</label>
                            <br />
                            <div style="display: flex">
                              <input
                                type="text"
                                class="form-control"
                                id="username"
                                placeholder="Entrer le nom d'utilisateur"
                                v-model="$store.state.currentUserInfo.username"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="email">Adresse email*</label>
                            <div style="display: flex">
                              <input
                                type="text"
                                class="form-control"
                                id="email"
                                placeholder="Enter your email address"
                                v-model="$store.state.currentUserInfo.email"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="profile">Quel est votre profil ?</label>
                            <textarea
                              class="form-control"
                              placeholder="Ex : Web developer"
                              v-model="$store.state.currentUserInfo.profile"
                              id="profile"
                              rows="5"
                            ></textarea>
                          </div>
                          <div class="pl-3">
                            Dernière connexion :
                            {{
                              this.$store.state.currentUserInfo.lastSignInTime
                            }}
                          </div>
                          <div style="margin-right: -12px">
                            <button class="btn btn-success float-right mr-4">
                              Mettre à jour
                            </button>
                          </div>
                        </form>
                        <div class="form-group mt-5">
                          <label>Image de profil :</label>
                          <div class="input-file input-file-image">
                            <img
                              class="img-upload-preview img-circle"
                              width="100"
                              height="100"
                              :src="this.$store.state.currentUserInfo.imageUrl"
                              alt="preview"
                            />
                            <input
                              type="file"
                              class="form-control form-control-file"
                              id="uploadImg"
                              name="uploadImg"
                              accept="image/*"
                              @change="uploadImage($event)"
                            />
                            <label
                              for="uploadImg"
                              v-if="!loading"
                              class="label-input-file btn"
                              style="background-color: #55b94b"
                              >Uploader</label
                            >
                            <p class="text-success" v-if="loading">
                              ...Chargement
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-quality-icons"
                      role="tabpanel"
                      aria-labelledby="v-pills-quality-tab-icons"
                    >
                      <div class="accordion accordion-secondary">
                        <div class="card">
                          <div
                            class="card-header"
                            id="headingFour"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                            role="button"
                          >
                            <div class="span-icon">
                              <div class="icon-information"></div>
                            </div>
                            <div class="span-title">
                              A propos de l'application
                            </div>
                            <div class="span-mode"></div>
                          </div>

                          <div
                            id="collapseFour"
                            class="collapse show"
                            aria-labelledby="headingFour"
                            data-parent="#accordion"
                            role="button"
                          >
                            <div class="card-body">
                              <p>
                                OkacodeHub est une plateforme de gestion des
                                ressources sous forme de liens. Elle permet à
                                chacun d’organiser ses ressources dans des
                                collections (qu’il faut voir comme des
                                classeurs) afin de les retrouver facilement.
                                Aussi, elle a été mise en place pour permettre
                                aux utilisateurs de se partager des ressources.
                                L'espace publique a été pensé pour partager des
                                ressources à toute la communauté. Les ressources
                                dans cet espace sont accessibles à tout le
                                monde. Ces ressources peuvent aider les
                                utilisateurs présents dans la plateforme à
                                découvrir un concept, mieux le comprendre ou à
                                approfondir des recherches dessus.
                              </p>
                              <p>Version de l'application : V6.5.8</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";

export default {
  name: "Profile",
  components: { Loader },
  data() {
    return {
      usersRef: myApp.firestore().collection("users"),
      Storage: myApp.storage().ref(),
      userId: this.$store.state.currentUserInfo.userId,
      loading: false,
      errorMessage: null,
      errorMessage2: null,
      successMessage: null,
      loaderState: false,
      socials: [
        {
          name: "Linkedin",
          key: "linkedin",
          icon: "fab fa-linkedin",
          value: "",
        },
        {
          name: "Medium",
          key: "medium",
          icon: "fab fa-medium",
          value: "",
        },
        {
          name: "Github",
          key: "github",
          icon: "fab fa-github",
          value: "",
        },
        {
          name: "Portofolio",
          key: "portofolio",
          icon: "far fa-gem",
          value: "",
          active: true,
        },
        {
          name: "Stack Overflow",
          key: "stack-overflow",
          icon: "fab fa-stack-overflow",
          value: "",
        },
      ],
    };
  },
  methods: {
    // Used to update user profile infos
    updateProfile() {
      this.loaderState = true;
      this.usersRef
        .where("user_id", "==", this.userId)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              const userName = this.$store.state.currentUserInfo.username;
              const userEmail = this.$store.state.currentUserInfo.email;
              const profile = this.$store.state.currentUserInfo.profile;

              if ((userName && userEmail) || profile) {
                this.usersRef
                  .doc(doc.id)
                  .update({
                    email: userEmail,
                    username: userName,
                    profile: profile,
                  })
                  .then(() => {
                    this.successMessage =
                      "Information de l'utilisateur mis à jour.";
                    this.loaderState = false;
                  })
                  .catch(() => {
                    this.errorMessage =
                      "Impossible de mettre à jour les infos de l'utilisateur";
                    this.loaderState = false;
                  });
              }
            });
          }
        });
    },

    updateImageUrl(url) {
      this.usersRef
        .where("user_id", "==", this.userId)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              this.usersRef
                .doc(doc.id)
                .update({ imageUrl: url })
                .then(() => {
                  this.loading = false;
                  alert("avatar uploadé");
                });
            });
          }
        });
    },

    uploadImage(event) {
      const metadata = {
        ContentLanguage: "en",
        contentType: "image/jpeg",
      };
      this.loading = true;
      let file = event.target.files[0];
      let fileName = "picture-" + file.lastModified;
      this.Storage.child("avatars/" + fileName)
        .put(file, metadata)
        .then(() => {
          this.getFileUrl(fileName);
        })
        .catch((error) => {
          alert(error.message);
        });
    },

    getFileUrl(url) {
      this.Storage.child("avatars/" + url)
        .getDownloadURL()
        .then((url) => {
          this.$store.state.currentUserInfo.imageUrl = url;
          this.updateImageUrl(url);
        });
    },

    saveSocialsSettings() {
      if (
        this.socials[0].value ||
        this.socials[1].value ||
        this.socials[2].value ||
        this.socials[3].value
      ) {
        this.loaderState = true;

        let socials = this.socials;
        this.usersRef
          .where("user_id", "==", this.userId)
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                this.usersRef
                  .doc(doc.id)
                  .update({ socials })
                  .then(() => {
                    this.loaderState = false;
                  });
              });
            }
          });
      } else
        this.errorMessage2 = "Vous devez renseigner au moins un réseau social";
    },
  },
  created() {
    // Getting social platforms values from DB
    this.usersRef
      .where("user_id", "==", this.userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            this.socials = doc.data().socials;
          });
        }
      });
  },
};
</script>

<style scoped>
.span-title {
  font-size: 16px;
}
.input-group-text {
  background: #fff;
  font-size: 20px;
}
.nav-link {
  font-size: 15px;
}
.card-body p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
