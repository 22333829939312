<template>
  <div class="wrapper">
    <div class="main-header">
      <!-- Logo Header -->
      <div class="logo-header">
        <router-link to="/home/fr/" class="logo">
          <img
            src="/images/logo.png"
            height="50px"
            alt="navbar brand"
            class="navbar-brand"
          />
        </router-link>
        <button
          class="navbar-toggler sidenav-toggler ml-auto"
          @click="mobileMenu = true"
        >
          <span class="navbar-toggler-icon">
            <i class="icon-menu"></i>
          </span>
        </button>
        <transition name="slide-fade">
          <ul class="mobile-menu" v-if="mobileMenu">
            <li class="pt-5 mt-5">
              <button
                class="btn mt-5 mb-2 btn-close"
                @click="mobileMenu = false"
              >
                <i class="fas fa-times"></i>
              </button>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/create-collection/fr/">
                <i class="icon-plus" style="padding-right: 10px"></i>Créer une
                collection
              </router-link>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/home/fr/">
                <i class="icon-folder-alt" style="padding-right: 10px"></i>Mes
                collections
              </router-link>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/public-resources/fr/">
                <i class="icon-grid" style="padding-right: 10px"></i>Tout le
                monde
              </router-link>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/advanced-search/fr/">
                <i class="icon-hourglass" style="padding-right: 10px"></i
                >Recherche avancée
              </router-link>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/community/fr/">
                <i class="icon-people" style="padding-right: 10px"></i
                >Communauté
              </router-link>
            </li>
            <li @click="mobileMenu = false">
              <router-link to="/profile/fr/">
                <i class="icon-user" style="padding-right: 10px"></i>Profil
              </router-link>
            </li>
            <li>
              <button
                class="btn btn-success float-right mt-3 mb-3"
                @click="signOut()"
              >
                Se déconnecter
              </button>
            </li>
          </ul>
        </transition>
      </div>
      <!-- End Logo Header -->

      <!-- Navbar Header -->
      <nav class="navbar navbar-header navbar-expand-lg">
        <div class="container-fluid">
          <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li class="nav-item">
              <router-link to="/profile/fr/" class="nav-link">
                Profil
              </router-link>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <router-link
                class="nav-link dropdown-toggle"
                to="/notifications/fr/"
                role="button"
              >
                <i class="icon-bell"></i>
                <span v-if="countNotifs != 0" class="notification">{{
                  countNotifs
                }}</span>
              </router-link>
            </li>
            <li class="nav-item" @click="signOut()">
              <a href="#" class="nav-link"> Se déconnecter </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- End Navbar -->
    </div>

    <!-- Sidebar -->
    <div class="sidebar sidebar-style-2">
      <div class="sidebar-wrapper scrollbar scrollbar-inner">
        <div class="sidebar-content">
          <div class="user">
            <div class="avatar-sm float-left mr-2">
              <img
                :src="this.$store.state.currentUserInfo.imageUrl"
                alt="..."
                class="avatar-img rounded-circle"
              />
            </div>
            <div class="info">
                <span style="color:#575962">  {{ truncString(username, 15) }}</span> <br>
                <span
                  class="font-weight-bold badge"
                  v-if="username !== 'incognito'"
                >
                  En ligne
                </span>
                <span
                  class="font-weight-bold badge"
                  v-if="username === 'incognito'"
                >
                  Hors ligne
                </span>         
            </div>
          </div>
          <ul class="nav nav-primary">
            <li class="nav-item active">
              <router-link to="/create-collection/fr/" class="btn bg-green">
                <i class="icon-plus"></i>
                <p>Créer une collection</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/home/fr/">
                <i class="icon-folder-alt"></i>
                <p>Mes collections</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/public-resources/fr/">
                <i class="icon-grid"></i>
                <p>Tout le monde</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/advanced-search/fr/">
                <i class="icon-hourglass"></i>
                <p>Recherche avancée</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/community/fr/">
                <i class="icon-people"></i>
                <p>Communauté</p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Sidebar -->

    <div class="main-panel">
      <router-view></router-view>
      <Footer />
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Footer from "../../components/fr/Footer";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "Navbar",
  components: { Footer },
  mixins: [Utilities],

  data() {
    return {
      searchItem: null,
      username: "incognito",
      userEmail: null,
      results: [],
      mobileMenu: false,
      usersRef: myApp.firestore().collection("users"),
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      notifsRef: myApp.firestore().collection("notifications"),
      countNotifs: 0,
    };
  },
  methods: {
    signOut() {
      this.loaderState = true;
      myApp
        .auth()
        .signOut()
        .then(() => {
          this.loaderState = false;
          this.$router.replace("/fr/");
        })
        .catch(() => {
          SweetAlert.errorMessage("Erreur lors de la déconnexion");
        });
    },
  },

  created() {
    const userId = this.$store.state.currentUserInfo.userId;
    this.usersRef
      .where("user_id", "==", userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            let obj = doc.data();
            this.username = obj.username;
            this.userEmail = obj.email;
          });
        }
      });

    // Counting number of unread notifications
    this.notifsRef.onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        this.countNotifs = 0;
        snapshot.forEach((doc) => {
          if (!doc.data().users_viewed.includes(userId)) this.countNotifs++;
        });
        if (this.countNotifs !== 0 && this.countNotifs !== null)
          document.title = "(" + this.countNotifs + ")";
      }
    });
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.mobile-menu {
  background: #f5f5f5;
  width: 100%;
  margin-top: 80%;
  position: fixed;
}
.mobile-menu li {
  border-bottom: 1px solid #57596240 !important;
  list-style: none;
  padding-right: 10px !important;
}
.mobile-menu a {
  color: #575962;
}
.mobile-menu a:first-child li {
  padding-top: 150px !important;
}
.mobile-menu a:focus {
  color: #55b94b;
}
.navbar-toggler {
  background: #55b94b !important;
  padding: 10px;
  height: 40px;
  margin-left: 20px !important;
}
.btn-close {
  position: relative;
  left: 80%;
}

@media screen and (max-width: 991px) {
  .logo-header {
    padding: 0 !important;
  }
}
.bg-green {
  background: #55b94b !important;
}
.badge {
  padding: 5px;
  font-size: 11px;
  color: #575962;
}
</style>
