// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBR4A7MwQhF5oNcgF4mBm50HEMoeXzJgrU",
  authDomain: "keep-it-435d8.firebaseapp.com",
  databaseURL: "https://keep-it-435d8.firebaseio.com",
  projectId: "keep-it-435d8",
  storageBucket: "keep-it-435d8.appspot.com",
  messagingSenderId: "599512927949",
  appId: "1:599512927949:web:f54da28787e12e809a6384",
  measurementId: "G-N1WYEVKH87",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp;
