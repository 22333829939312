var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.loaderState)?_c('Loader'):_vm._e(),_vm._m(0),_c('div',{staticClass:"page-inner page-inner-2"},[_c('div',{staticClass:"row"},[(_vm.users.length == 0)?_c('div',{staticClass:"text-center",staticStyle:{"margin":"auto"}},[_c('img',{attrs:{"src":"/images/tool.gif","height":"100","width":"100","alt":""}}),_vm._v(" "),_c('br'),_c('br'),_c('p',[_vm._v("Aucun utilisateur dans la page communauté.")])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-list"},_vm._l((_vm.users),function(user,index){return _c('div',{key:index,staticClass:"item-list",staticStyle:{"border-bottom":"1px solid #c3c8cb"}},[_c('div',{staticClass:"avatar"},[_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":user.imageUrl,"alt":"avatar"}})]),_c('div',{staticClass:"info-user"},[_c('div',{staticClass:"username"},[_vm._v("@"+_vm._s(user.username))]),_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(user.profile)+" ")])]),_c('div',{staticClass:"status"},[_vm._v(" Privé ("+_vm._s(user.privateCol || 0)+") "),_c('i',{staticClass:"icon-folder icon-position"})]),_c('div',{staticClass:"status"},[_vm._v(" Public ("+_vm._s(user.publicCol || 0)+") "),_c('i',{staticClass:"icon-folder icon-position"})]),_c('router-link',{staticClass:"btn btn-view btn-success",attrs:{"to":{
                    name: 'ReachMeView_FR',
                    params: {
                      docId: user.id,
                      user_id: user.user_id,
                      pubCol: user.publicCol,
                      privCol: user.privateCol,
                    },
                  }}},[_vm._v(" Découvrir ")])],1)}),0)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-header"},[_c('div',{staticClass:"page-inner py-5 page-bg"},[_c('div',{staticClass:"d-flex align-items-left align-items-md-center flex-column flex-md-row"},[_c('div',[_c('h2',{staticClass:"pb-2 fw-bold community-title"},[_vm._v("Notre communauté")]),_c('p',{staticStyle:{"color":"#fff","font-size":"18px"}},[_vm._v(" Découvrez notre communauté ")])])])])])
}]

export { render, staticRenderFns }