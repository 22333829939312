<template>
  <div class="container container-full">
    <Loader v-if="loaderState" />
    <div class="page-inner page-inner-fill">
      <div class="mail-wrapper">
        <div
          class="page-content mail-content bg-white"
          v-if="collection_status == 'public'"
        >
          <h3 class="pl-30 pt-30 h3-style">{{ collection_name }}</h3>
          <p class="pl-30">
            <span v-if="ambassador"><i class="fas fa-certificate"></i></span>
          </p>
          <p class="pl-30 published-by">Publié par: @{{ username }}</p>
          <p class="pl-30 views" v-if="collection_views.length == 1">
            {{ collection_views.length }} Vue
          </p>
          <p class="pl-30 views" v-if="collection_views.length > 1">
            {{ collection_views.length }} Vues
          </p>
          <p class="pl-30 text-descr">{{ collection_descr }}</p>
          <div class="pl-30" v-if="datas.length === 0">
            Il n'y a pas de ressources dans cette collection ...
          </div>

          <div class="inbox-body" v-for="(data, index) in datas" :key="index">
            <div class="email-list">
              <div class="email-list-item">
                <div class="email-list-actions">
                  <div class="d-flex">
                    <div class="element-icon-style">
                      <img
                        :src="
                          'http://www.google.com/s2/favicons?domain=https://' +
                          data.content.split('//')[1]
                        "
                        alt="logo du site"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="hidden"
                  :id="'copy-element' + index"
                  :value="data.content"
                />
                <span
                  class="icon-copy float-right"
                  @click="copyLinkFunc(index, 'Fait', 'Votre lien a été copié')"
                  ><i class="icon-link"></i
                ></span>
                <span class="link-style"
                  ><a
                    :href="data.content"
                    target="_blank"
                    :title="data.content"
                    >{{ truncString(data.content, 70) }}</a
                  ></span
                >
                <p>{{ data.description }}</p>
                <span class="tag-style">{{
                  truncString(reformatTag(data.tag), 30)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="page-content mail-content bg-white"
          v-if="collection_status == 'private'"
        >
          <div class="text-center" style="margin: auto">
            <img src="/images/folder.gif" height="100" width="100" alt="" />
            <br />
            <br />
            <h2>Désolé!</h2>
            <p>
              Cette collection de ressources est privée. Vous ne pouvez pas
              accéder à son contenu.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import Utilities from "../../mixins/utilities";

export default {
  name: "PublicSharedResource",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      created_date: "",
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      usersRef: myApp.firestore().collection("users"),
      folderId: null,
      userId: this.$store.state.currentUserInfo.userId,
      datas: [],
      viewed_users: [],
      username: "Utilisateur inconnu",
      loaderState: false,
      collection_name: null,
      collection_descr: null,
      collection_views: [],
      collection_status: "",
      ambassador: false,
      smallLoad: false,
    };
  },
  methods: {
    // This func allows to get all datas in a given collection
    getAllDatasInsideFolder() {
      let obj = {};
      this.dataRef
        .where("folderId", "==", this.folderId)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          this.datas = [];
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              this.datas.push(obj);
              this.getUser(obj.userId);
              this.loaderState = false;
            });
          }
          this.loaderState = false;
        });
    },

    // This func gets the user who shared the collection of resources
    getUser(userId) {
      this.usersRef
        .where("user_id", "==", userId)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              this.username = doc.data().username;
              this.ambassador = doc.data().socials.ambassador
            })
          }
        })
    },

    countNumberOfViews(collection_id) {
      if (!this.collection_views.includes(this.userId)) {
        this.collection_views.push(this.userId);
        this.folderRef
          .doc(collection_id)
          .update({ views: this.collection_views })
      }
    }
  },
  created() {
    this.smallLoad = true;
    // Get current collection id
    this.folderId = this.$route.params.id;
    this.folderRef.doc(this.folderId).onSnapshot((doc) => {
      if (doc.exists) {
        let obj = doc.data();
        this.created_date = new Date(obj.timestamp);
        this.collection_name = obj.name;
        this.collection_descr = obj.description;
        this.collection_views = obj.views;
        this.smallLoad = false;
        this.countNumberOfViews(doc.id);
        this.collection_status = obj.status;
        // Checks if the collection is public before getting data from it
        if (obj.status === "public") this.getAllDatasInsideFolder();
      }
    });
  },
};
</script>

<style scoped>
.link-style {
  font-size: 15px;
}
h3 {
  font-weight: bold;
}
.pl-30 {
  padding-left: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.text-descr {
  font-size: 15px;
  padding-left: 40px;
}
.mail-content {
  border: 1px solid #e3e6e8;
  margin: auto;
  width: 80%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
}
.inbox-body {
  margin: auto;
  width: 95% !important;
}
.tag-style {
  color: #fff;
  background-color: #f9826c;
  border: 1px solid #f9826c;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
}
p {
  font-size: 15px !important;
}
.icon-copy {
  font-size: 15px;
  background: #e3e6e8;
  padding: 3px 6px;
  border-radius: 3px 3px 0 0;
}
.icon-copy:hover {
  border-bottom: 2px solid #f9826c;
}
.h3-style {
  background-image: url("/images/visuel-2.jpg");
  padding: 40px 0px;
  padding-left: 15px;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
  font-size: 28px;
}
.published-by,
.views {
  font-weight: bold;
  padding-left: 40px;
}
</style>
