<template>
  <div class="container container-full">
    <Loader v-if="loaderState" />
    <div class="page-navs bg-white">
      <div class="nav-scroller">
        <p class="p-style">
          <i class="fa fa-info-circle"></i> Rechercher une ressource dans toutes les collections
        </p>
      </div>
    </div>
    <div class="page-inner">
      <div class="row content">
          <div class="col-lg-12">
            <div class="form-group">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text fa fa-folder" v-if="!smallLoad"></span>
                <SmallLoader style="float: right" v-if="smallLoad" class="small-load" />
              </div>
              <input type="text" class="form-control" placeholder="Entrez le tag de la ressource"  v-model="searchItem">
              <div class="input-group-append">
                <span class="input-group-text bg-btn-clr" @click="searching()">Rechercher</span>
              </div>
            </div>
          </div>
          <p v-if="notFound">Ressource non trouvée. Vérifiez que vous avez entré un bon mot clé</p>
            <section class="card mb-3"  v-for="(item, index) in results" :key="index">
              <div class="list-group list-group-messages list-group-flush">
                <div class="list-group-item unread">
                  <div class="list-group-item-figure">
                    <span class="rating-sm mr-3">
                      <label>
                        <span class="fa fa-folder"></span>
                        collection : 
                      </label>
                    </span>
                  </div>
                  <div class="list-group-item-body">
                    <div class="row">
                      <div class="col-12 col-lg-10">
                        <h5 class="list-group-item-title">
                          {{ item.folder_name }}
                        </h5>
                        <p class="list-group-item-text text-truncate"> {{ item.content }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import SmallLoader from "../../components/loader/smallLoader";
import Utilities from "../../mixins/utilities";

export default {
  name: "AdvancedSearchView",
  components: { Loader, SmallLoader },
  mixins: [Utilities],

  data() {
    return {
      loaderState: false,
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      searchItem: null,
      results: [],
      smallLoad: false,
      userId: this.$store.state.currentUserInfo.userId,
      notFound: false
    };
  },
  methods: {
    searching() {
      this.smallLoad = true;
      this.results = [];
      this.dataRef
        .where("userId", "==", this.$store.state.currentUserInfo.userId)
        .where("tag", "==", this.searchItem)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            this.results = [];
            this.notFound = false;
            snapshot.forEach((doc) => {
              const obj = doc.data();
              this.getFolderName(obj.folderId, obj);
            })
          }
          else this.notFound = true
        })
    },

    getFolderName(folderId, obj) {
      this.folderRef
        .doc(folderId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            obj.folder_name = doc.data().name;
            this.results.push(obj);
            this.smallLoad = false;
          }
        })
    },
  },
};
</script>

<style scoped>
.tag-style {
  color: #fff;
  background-color: #f9826c !important;
  border: 1px solid #f9826c;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
}
label span {color: #607d8b}
.p-style {
  padding-top: 20px;
  padding-bottom: 10px;
}
.small-load {
  margin-right: 30px;
  margin-top: 5px;
}
.bg-btn-clr{
  background: #55b94b;
  color: #fff;
}
span.bg-btn-clr{cursor: pointer;}
.content {
  border: 1px solid #ddd;
}
.list-group-item { 
  padding: 8px 10px;
  background: #f3f2ef;
}
</style>
