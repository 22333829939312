<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <form
                    @submit.prevent="createCollection()"
                    method="POST"
                    action="#"
                  >
                    <div class="form-group">
                      <label for="folder_name">Nom de la collection*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex: Firebase, authentification des utilisateurs dans Vue.js"
                        id="folder_name"
                        name="folder_name"
                        v-model="collection.name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="description">Description*</label>
                      <textarea
                        class="form-control"
                        placeholder="Ajouter la description ici ..."
                        rows="5"
                        name="description"
                        id="description"
                        v-model="collection.description"
                      ></textarea>
                    </div>
                    <div class="form-check">
                      <label>Etat de la collection</label><br />
                      <label class="form-radio-label ml-3">
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="private"
                          value="private"
                          v-model="collection.status"
                        />
                        <i class="fa fa-lock pl-2"></i>
                        <span class="form-radio-sign pl-1">Privé</span>
                      </label>
                      <p v-if="collection.status !== 'private'">
                        <i class="fa fa-info-circle"></i>Tout le monde peut le
                        voir.
                      </p>
                    </div>
                    <div class="card-action">
                      <div class="float-right">
                        <router-link to="/home/en/" class="btn btn-danger mr-2"
                          >Annuler</router-link
                        >
                        <button class="btn btn-success">Enregistrer</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import SweetAlert from "../../classes/fr/messages";
import * as fb from "firebase/app";

export default {
  name: "CreateCollection",
  components: { Loader },

  data() {
    return {
      folderRef: myApp.firestore().collection("folders"),
      collection: {
        name: "",
        type: "folder",
        description: "",
        status: "private",
        views: [],
      },
      loaderState: false,
      errorMessage: null,
    };
  },
  methods: {
    //  Creates folder
    createCollection() {
      if (this.collection.name !== "" && this.collection.description !== "") {
        this.loaderState = true;
        this.collection.timestamp = fb.firestore.FieldValue.serverTimestamp();
        this.collection.userId = this.$store.state.currentUserInfo.userId;
        this.folderRef
          .add(this.collection)
          .then(() => {
            this.$router.replace({ name: "Home" });
            this.loaderState = false;
            this.collection.name = "";
          })
          .catch(() => {
            // Display ERROR_MESSAGE
            SweetAlert.errorMessage("Impossible de créer une collection");
            this.loaderState = false;
          });
      } else {
        this.errorMessage = "Assurez-vous que tous les champs sont remplis";
      }
    },
  },
};
</script>

<style scoped></style>
