import Vue from "vue";
import App from "./App.vue";
import router from "./routes/route";
import VueRouter from "vue-router";
import myApp from "./firebase/init";
import Vuex from "vuex";
import store from "./store/store";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.config.productionTip = false;

let app;
myApp.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      user,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
