<template>
  <div class="container container-full">
    <Loader v-if="loaderState" />
    <div class="page-navs bg-white">
      <div class="nav-scroller">
        <div
          class="nav nav-tabs nav-line nav-color-secondary d-flex align-items-center justify-contents-center w-100"
        >
          <a
            class="nav-link show"
            router-link-active="'active'"
            href="#tab1"
            @click="tab = 'tab1'"
            >Mes collections
            <span class="count ml-1">({{ this.collections.length }})</span>
          </a>

          <a
            class="nav-link mr-5"
            router-link-active="'active'"
            href="#tab2"
            @click="tab = 'tab2'"
            >Partagé avec moi
            <span class="count ml-1"
              >({{ this.shared_collections.length }})</span
            >
          </a>
          <div class="ml-auto">
            <router-link
              to="/create-collection/fr/"
              style="margin-bottom: 10px"
              class="btn btn-success"
              >Nouvelle collection</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="page-inner" v-if="tab === 'tab1'">
      <div class="collapse mb-5" id="search-nav">
        <form class="navbar-left navbar-form nav-search mr-md-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="submit" @click.prevent class="btn btn-search pr-1">
                <i class="icon-magnifier search-icon"></i>
              </button>
            </div>
            <input
              type="text"
              placeholder=" Rechercher une collection ..."
              class="form-control"
              v-model="searchItem"
              @input="searchElement"
            />
          </div>
        </form>
      </div>
      <div class="row row-projects">
        <div
          v-if="searchElement().length === 0"
          class="text-center"
          style="margin: auto"
        >
          <img src="/images/folder.gif" height="100" width="100" alt="" />
          <br />
          <br />
          <p>
            Vous n'avez pas encore de collections des ressources. Cliquez ici
            <b>"Créer une collection"</b> pour commencer.
          </p>
        </div>

        <div
          class="col-sm-6 col-lg-4"
          v-for="(collection, index) in searchElement()"
          :key="index"
        >
          <div class="card">
            <div class="pt-2 pl-4 pb-1 pr-2">
              <button
                class="bt-icon float-right icon-options"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <router-link
                  class="dropdown-item"
                  :to="'/edit-collection/fr/' + collection.id"
                >
                  Editer
                </router-link>
                <router-link
                  :to="'/share-to/fr/' + collection.id"
                  class="dropdown-item"
                  >Partager à</router-link
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click="deleteCollection(collection)"
                  >Supprimer</a
                >
              </div>
              <div
                @click="
                  storeCollectionData(
                    collection.name,
                    collection.description,
                    collection.id
                  )
                "
                :title="collection.name"
              >
                <h5 class="mb-1 fw-bold p-h5">
                  <p class="p-rs">{{ truncString(collection.name, 30) }}</p>
                </h5>
                <p>{{ truncString(collection.description, 30) }}</p>
              </div>
              <span v-if="collection.shared"><i class="fa fa-users"></i></span>
              <span
                v-if="collection.status === 'public'"
                style="padding-left: 10px"
                ><i class="fa fa-eye"></i
              ></span>
              <span class="nb-docs">{{ collection.nbDocs }}</span>
              <SmallLoader
                style="float: right"
                v-if="smallLoad"
                class="small-load"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-inner" v-if="tab === 'tab2'">
      <div class="row row-projects">
        <p v-if="shared_collections.length === 0">
          <i class="fa fa-info-circle"></i>Aucune collection partagée avec moi
        </p>
        <div
          class="col-sm-6 col-lg-4"
          v-for="(collection, index) in shared_collections"
          :key="index"
        >
          <div class="card">
            <div class="pt-2 pl-4 pb-1 pr-2">
              <h5 class="mb-1 fw-bold p-h5">
                <router-link
                  :to="'/view-shared-resource/fr/' + collection.id"
                  style="color: #003f75"
                  :title="collection.name"
                >
                  {{ truncString(collection.name, 30) }}
                </router-link>
              </h5>
              <p :title="collection.description" class="pb-1">
                {{ truncString(collection.description, 30) }}
              </p>
              <span><i class="icon-folder-alt"></i></span>
              <span class="pl-2">{{
                new Date(collection.shared_when).toLocaleDateString("fr-FR")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import SmallLoader from "../../components/loader/smallLoader";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "Home",
  components: { Loader, SmallLoader },
  mixins: [Utilities],

  data() {
    return {
      loaderState: false,
      isVisible: "visible",
      tab: "tab1",
      smallLoad: false,
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      sharedCollectionRef: myApp.firestore().collection("shared_resources"),
      searchItem: null,
      collections: [],
      shared_collections: [],
      userId: this.$store.state.currentUserInfo.userId,
    };
  },
  methods: {
    storeCollectionData(name, descr, ref) {
      localStorage.setItem("name", name);
      localStorage.setItem("descr", descr);
      localStorage.setItem("id_res", ref);
      const slug = name.split(" ").join("-").toLowerCase();
      this.$router.push(`/view-resource/fr/${slug}`);
    },

    // Deletes folder and data inside
    deleteCollection(folder) {
      SweetAlert.deleteMessage().then((result) => {
        if (result) {
          this.loaderState = true;
          this.folderRef
            .doc(folder.id)
            .delete()
            .then(() => {
              this.dataRef
                .where("folderId", "==", folder.id)
                .get()
                .then((snapshot) => {
                  if (!snapshot.empty) {
                    snapshot.forEach((doc) => {
                      // Deletion of data inside a folder
                      this.dataRef.doc(doc.id).delete();
                      this.loaderState = false;
                    });
                  } else this.loaderState = false;
                });
            })
            .catch(() => {
              SweetAlert.errorMessage("Impossible de supprimer la collection");
              this.loaderState = false;
            });
        }
      });
    },

    // This function returns the number of elements inside a folder and push all datas in an array
    getNumberOfElementsInFolder(Obj, folderId) {
      this.smallLoad = true;
      this.dataRef
        .where("userId", "==", this.userId)
        .where("folderId", "==", folderId)
        .onSnapshot((snapshot) => {
          Obj.nbDocs = snapshot.size;
          this.smallLoad = false;
        });
    },

    // Searching through collections
    searchElement() {
      return this.collections.filter(
        (data) =>
          !this.searchItem ||
          data.name.toLowerCase().includes(this.searchItem.toLowerCase())
      );
    },
  },

  created() {
    this.loaderState = true;
    this.searchItem = null;

    // Getting collections from DB
    this.folderRef
      .where("userId", "==", this.userId)
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          this.collections = [];
          snapshot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            obj.nbDocs = null;
            this.collections.push(obj);
            this.getNumberOfElementsInFolder(obj, doc.id);
          });
        }

        this.loaderState = false;
      });

    this.sharedCollectionRef
      .where("res_receiver_id", "==", this.userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            let collection_id = doc.data().collection_id;
            let sharedWhen = doc.data().shared_when;
            this.folderRef
              .doc(collection_id)
              .get()
              .then((doc) => {
                let obj = doc.data();
                obj.id = doc.id;
                obj.shared_when = sharedWhen;
                this.shared_collections.push(obj);
              });
          });
        }
      });
  },
};
</script>

<style scoped>
.p-rs {
  font-weight: 600;
  font-size: 15px;
}
p {
  margin-bottom: 0 !important;
  line-height: none !important;
}
.p-h5 {
  color: #003f75 !important;
  padding-top: 10px !important;
}
.bt-icon {
  background-color: #fff;
  border: none;
}
.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.card:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}
.nb-docs {
  background-color: #f9826c;
  padding: 1px 7px;
  border-radius: 50px;
  color: #fff;
  float: right;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:focus {
  border-color: #f9826c;
}
i {
  color: #8d9498;
}
</style>
