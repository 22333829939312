<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="card">
            <img
              src="/images/people-transparent.png"
              alt="People"
              class="people-image"
            />
            <div class="card-header" v-if="authorized">
              <h3 class="card-title">{{ collectionName }}</h3>
            </div>
            <div class="card-body" v-if="authorized">
              <h5 class="mb-3">
                <span class="status-style"
                  >Statut: {{ collection_status }}</span
                >
              </h5>
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <h3 class="text-danger">
                    <i class="fa fa-info-circle"></i>Important!
                  </h3>
                  <p>
                    Publier une collection signifie la rendre visible pour tout
                    le monde dans le système. Une fois qu'elle est publiée, tout
                    le monde peut voir la collection et les ressources qu'elle
                    contient.
                  </p>
                  <p class="link-style">
                    <span
                      class="date float-right"
                      style="padding-left: 10px"
                      @click="copyLinkFunc(1, 'Fait', 'Votre lien a été copié')"
                      ><i class="far fa-copy paperclip"></i
                    ></span>
                    <input
                      type="hidden"
                      id="copy-element1"
                      :value="resource_url"
                    />
                    <span style="font-size: 14px"
                      ><a :href="resource_url" target="_blank">{{
                        resource_url
                      }}</a></span
                    >
                  </p>

                  <div class="success-message" v-if="success_message">
                    {{ success_message }}
                  </div>
                  <div class="error-message" v-if="!hasData">
                    Votre collection n'a pas de ressources.
                  </div>
                  <div class="card-action pl-1 float-right">
                    <a href="#" @click="back()" class="btn btn-danger"
                      >Annuler</a
                    >
                    <button
                      class="btn btn-success ml-2"
                      @click="turnToPublic"
                      v-if="collection_status == 'private'"
                    >
                      Rendre public
                    </button>
                    <button
                      class="btn btn-success ml-2"
                      @click="turnToPrivate"
                      v-if="collection_status == 'public'"
                    >
                      Rendre privé
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "PublishResource",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      notifsRef: myApp.firestore().collection("notifications"),
      userId: this.$store.state.currentUserInfo.userId,
      username: this.$store.state.currentUserInfo.username,
      collection_status: "private",
      folderId: null,
      success_message: null,
      resource_url: null,
      loaderState: false,
      hasData: false,
      collectionName: "",
      authorized: false,
    };
  },
  methods: {
    // Buttons turn to public and to private design must be changed
    //  This fun makes the resource public (visible to anyboby)
    turnToPublic() {
      this.loaderState = true;
      if (this.hasData === true) {
        this.folderRef
          .doc(this.folderId)
          .update({ status: "public" })
          .then(() => {
            this.saveNotifsInDB(this.userId, this.$route.params.id, this.username);
            SweetAlert.successMessage(
              "La collection est maintenant publique",
              "Tout le monde dans le système peut consulter cette collection."
            );
            this.collection_status = "public";
            this.loaderState = false;
          })
          .catch(() => {
            SweetAlert.errorMessage(
              "Impossible de rendre cette collection publique. Une erreur s'est produite !"
            );
            this.loaderState = false;
          });
      } else this.loaderState = false;
    },

    //  This func makes the resource private (visible only for the owner)
    turnToPrivate() {
      this.loaderState = true;
      this.folderRef
        .doc(this.folderId)
        .update({ status: "private" })
        .then(() => {
          SweetAlert.successMessage(
            "La collection est maintenant privée",
            "Vous êtes le seul à pouvoir consulter cette collection de ressources."
          );
          this.collection_status = "private";
          this.loaderState = false;
        })
        .catch(() => {
          SweetAlert.errorMessage(
            "Impossible de rendre cette collection privée. Une erreur s'est produite !"
          );
          this.loaderState = false;
        });
    },

    isCollectionEmpty() {
      this.dataRef
        .where("folderId", "==", this.folderId)
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            this.hasData = true;
          }
        });
    },
  },

  created() {
    // Get the id of the collection to be shared
    this.folderId = this.$route.params.id;

    // Get the current status of the collection
    this.folderRef.doc(this.folderId).onSnapshot((doc) => {
      if (doc.exists) {
        if (doc.data().userId == this.userId) {
          this.authorized = true;
          this.collection_status = doc.data().status;

          //  The public url of the collection to be shared
          this.resource_url =
            window.location.origin +
            "/public-resource-view/fr/" +
            this.$route.params.id;

          this.collectionName = localStorage.getItem("name");

          this.isCollectionEmpty();
        } else
          SweetAlert.errorMessage("Vous n'avez pas accès à cette ressource");
      }
    });
  },
};
</script>

<style scoped>
.success-message {
  color: #55b94b;
  font-size: 14px;
}
.error-message {
  color: #f56c6c;
  font-size: 14px;
}
.status-style {
  background: #e6a23c;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px;
}
.link-style {
  border: 1px solid #eeeeee;
  padding: 8px;
  cursor: pointer;
}
</style>
