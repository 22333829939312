// Routes imports and initialization

import VueRouter from "vue-router";
import Home_FR from "../components/fr/Home";
import Login_FR from "../components/fr/Login";
import Register_FR from "../components/fr/Register";
import Navbar_FR from "../components/fr/Navbar";
import NotFound_FR from "../components/fr/NotFound";
import Profile_FR from "../components/fr/Profile";
import ConfirmEmailAddress_FR from "../components/fr/ConfirmEmailAddress";
import PublishResource_FR from "../components/fr/PublishResource";
import ViewResources_FR from "../components/fr/ViewResources";
import CreateResource_FR from "../components/fr/CreateResource";
import PublicSharedResource_FR from "../components/fr/PublicSharedResource";
import AllPublicResources_FR from "../components/fr/AllPublicResources";
import EditResource_FR from "../components/fr/EditResource";
import EditCollection_FR from "../components/fr/EditCollection";
import CreateCollection_FR from "../components/fr/CreateCollection";
import Community_FR from "../components/fr/Community";
import ShareTo_FR from "../components/fr/ShareTo";
import ShareToResourcesView_FR from "../components/fr/ShareToResourcesView";
import ReachMeView_FR from "../components/fr/ReachMeView";
import AdvancedSearchView_FR from "../components/fr/AdvancedSearchView";
import ResetPassword_FR from "../components/fr/resetPassword";
import NotifsPage_FR from "../components/fr/NotifsPage";
import Welcome_FR from "../components/fr/Welcome";

import myApp from "../firebase/init";

// Routes for the french version
const routes = [
  {
    path: "/home/fr/",
    component: Navbar_FR,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "PublishResource",
        path: "/publish-resource/fr/:id",
        component: PublishResource_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "AllPublicResources",
        path: "/public-resources/fr/",
        component: AllPublicResources_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "PublicSharedResourceView",
        path: "/public-resource-view/fr/:id",
        component: PublicSharedResource_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Resource_FR",
        path: "/view-resource/fr/:name",
        component: ViewResources_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NewResource",
        path: "/new-resource/fr/:ref",
        component: CreateResource_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "EditResource",
        path: "/edit-resource/fr/:id",
        component: EditResource_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "CreateCollection",
        path: "/create-collection/fr/",
        component: CreateCollection_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "EditCollection",
        path: "/edit-collection/fr/:id",
        component: EditCollection_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Home",
        path: "/home/fr/",
        component: Home_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Profile",
        path: "/profile/fr/",
        component: Profile_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Community",
        path: "/community/fr/",
        component: Community_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "ShareToResourcesView",
        path: "/view-shared-resource/fr/:id_collection",
        component: ShareToResourcesView_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "ShareTo",
        path: "/share-to/fr/:id_collection",
        component: ShareTo_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "AdvancedSearchView",
        path: "/advanced-search/fr/",
        component: AdvancedSearchView_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "ReachMeView_FR",
        path: "/reachme/:pubCol/fr/:privCol/:docId/:user_id",
        component: ReachMeView_FR,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "NotifsPage",
        path: "/notifications/fr/",
        component: NotifsPage_FR,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "Login",
    path: "/login/fr/",
    component: Login_FR,
  },
  {
    name: "Register",
    path: "/register/fr/",
    component: Register_FR,
  },
  {
    name: "ConfirmEmailAddress",
    path: "/email-verify/fr/",
    component: ConfirmEmailAddress_FR,
  },
  {
    name: "ResetPassword",
    path: "/reset_password/fr/",
    component: ResetPassword_FR,
  },
  {
    path: "/",
    redirect: "/fr/",
  },
  {
    name: "Welcome",
    path: "/fr/",
    component: Welcome_FR,
  },
  {
    name: "404",
    path: "*",
    component: NotFound_FR,
  },
];

//  Routes initialization
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

/*
  These are route guards. Only authenticated user 
  will access some part of the app
  */

router.beforeEach((to, from, next) => {
  let firebaseUser = "";
  if (myApp.auth().currentUser) {
    if (myApp.auth().currentUser.emailVerified) firebaseUser = myApp.auth();
  }
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  if (requiresAuth && !firebaseUser) next({ name: "Login" });
  // Redirect to login page when the non logged in user try to access pages
  else if (!requiresAuth && firebaseUser) next({ name: "Home" });
  // Prevent from go to login or register page when the user is logged in
  // else if (!requiresAuth && !firebaseUser) next()
  else next();
});

export default router;
