<template>
  <div class="page-wrapper" style="background-color: #f5f5f5">
    <div class="container-fluid">
      <div id="section1">
        <div class="container">
          <div class="topheader">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="#">
                <img
                  src="/images/logo.png"
                  class="d-inline-block align-top mr-2"
                  height="45"
                />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon">
                  <i class="icon-menu"></i>
                </span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/fr/"
                      >Accueil</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="/docs/okacodehub-documentation.pdf"
                      target="_blank"
                      >Documentation</a
                    >
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="row row-info pt-5">
            <div class="col-lg-6 info-section">
              <h1 class="title font-weight-bold">
                <span>Découvrir OkacodeHub</span>
              </h1>
              <p class="subtitle">
                OkacodeHub est une plateforme de gestion des ressources sous
                forme de liens. Elle permet à chacun d’organiser ses ressources
                dans des collections (qu’il faut voir comme des classeurs) afin
                de les retrouver facilement. Aussi, elle a été mise en place
                pour permettre aux utilisateurs de se partager des ressources.
                L'espace publique a été pensé pour partager des ressources à
                toute la communauté. Les ressources dans cet espace sont
                accessibles à tout le monde. Ces ressources peuvent aider les
                utilisateurs présents dans la plateforme à découvrir un concept,
                mieux le comprendre ou à approfondir des recherches dessus.
              </p>
              <div class="d-flex">
                <router-link to="/login/fr/" class="btn bg-color-green"
                  >Se connecter</router-link
                >
                <router-link to="/register/fr/" class="btn bg-color-green ml-3"
                  >S'inscrire</router-link
                >
              </div>
            </div>
            <div class="col-lg-6 img-section">
              <div class="imgbox">
                <img src="/images/app-view-new.png" class="imgpreview" />
              </div>
            </div>
          </div>
          <div class="row row-info pt-5 mt-5">
            <div class="col-lg-6 img-section">
              <div class="imgbox">
                <img
                  src="/images/teleportation-transparent.png"
                  class="imgpreview"
                />
              </div>
            </div>
            <div class="col-lg-6 info-section">
              <h1 class="title font-weight-bold pb-5 text-center">
                <span>Stats OkacodeHub</span>
              </h1>
              <div class="row">
                <div class="col-sm-8 col-md-12">
                  <div class="card card-stats zoom card-round">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-2">
                          <div class="icon-big text-center">
                            <i
                              class="flaticon-analytics icon-color"
                              v-if="!smallLoad3"
                            ></i>
                            <SmallLoader
                              style="float: right"
                              v-if="smallLoad3"
                              class="small-load"
                            />
                          </div>
                        </div>
                        <div class="col-8 col-stats">
                          <div class="numbers">
                            <p class="card-category size-18 text-center">
                              Collections de ressources créées
                            </p>
                            <h4
                              class="card-title text-center"
                              v-if="!smallLoad3"
                            >
                              {{ nbFolders }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-12">
                  <div class="card card-stats zoom card-round">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-2">
                          <div class="icon-big text-center">
                            <i
                              class="flaticon-analytics icon-color"
                              v-if="!smallLoad2"
                            ></i>
                            <SmallLoader
                              style="float: right"
                              v-if="smallLoad2"
                              class="small-load"
                            />
                          </div>
                        </div>
                        <div class="col-8 col-stats">
                          <div class="numbers">
                            <p class="card-category size-18 text-center">
                              Ressources ajoutées par les utilisateurs
                            </p>
                            <h4
                              class="card-title text-center"
                              v-if="!smallLoad2"
                            >
                              {{ nbRes }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-12">
                  <div class="card card-stats zoom card-round">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-2">
                          <div class="icon-big text-center">
                            <i
                              class="flaticon-analytics icon-color"
                              v-if="!smallLoad1"
                            ></i>
                            <SmallLoader
                              style="float: right"
                              v-if="smallLoad1"
                              class="small-load"
                            />
                          </div>
                        </div>
                        <div class="col-8 col-stats">
                          <div class="numbers">
                            <p class="card-category size-18 text-center">
                              Collections de ressources en public
                            </p>
                            <h4
                              class="card-title text-center"
                              v-if="!smallLoad1"
                            >
                              {{ nbPub }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myApp from "../../firebase/init";
import SmallLoader from "../../components/loader/smallLoader";

export default {
  name: "Welcome",
  components: { SmallLoader },

  data() {
    return {
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      nbFolders: 0,
      nbRes: 0,
      nbPub: 0,
      smallLoad1: false,
      smallLoad2: false,
      smallLoad3: false,
    };
  },

  created() {
    this.smallLoad1 = true;
    this.smallLoad2 = true;
    this.smallLoad3 = true;

    // Getting collections from DB
    this.folderRef.onSnapshot((snapshot) => {
      this.nbFolders = snapshot.size;
      this.smallLoad1 = false;
    });

    // Getting collections from DB
    this.folderRef.where("status", "==", "public").onSnapshot((snapshot) => {
      this.nbPub = snapshot.size;
      this.smallLoad2 = false;
    });

    // Getting resources from DB
    this.dataRef.onSnapshot((snapshot) => {
      this.nbRes = snapshot.size;
      this.smallLoad3 = false;
    });
  },
};
</script>
<style scoped>
.display-inline-block {
  display: inline-block;
  margin-right: 30px;
}
a:hover {
  color: #575962;
}
.subtitle {
  text-align: justify;
}
.btn-label {
  font-size: 20px !important;
  padding: 4px 0;
}
.size-18 {
  font-size: 18px;
}
.btn {
  background: #55b94b70;
  transition: 0.3 s;
}
.btn:hover {
  background: #31ce36;
  color: #fff;
}
.zoom:hover {
  transform: scale(1.1);
}
.zoom {
  transition: transform 0.2s;
}
.icon-color {
  color: #31ce36;
}
</style>
