<template>
  <div class="login">
    <div class="wrapper wrapper-login">
      <Loader v-if="loaderState" />
      <div class="container container-login">
        <span class="error-msg">{{ errorMessage }}</span>
        <h3 class="text-center">
          <img src="/images/logo.png" alt="logo okacode hub" />
        </h3>
        <p class="text-success">{{ successMessage }}</p>
        <p class="text-danger">{{ errorMessage }}</p>
        <form @submit.prevent="resetPassword" method="POST" action="#">
          <div class="login-form">
            <div class="form-group form-floating-label">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Entrez votre adresse e-mail"
                v-model="email"
                class="form-control input-border-bottom"
              />
            </div>
            <div class="form-action mb-3">
              <button class="btn btn-success font-weight-bold">
                Réinitialiser le mot de passe
              </button>
            </div>
          </div>
        </form>
        <div class="login-account">
          <span class="msg font-15" style="padding-right: 5px"
            >Vous n'avez pas encore de compte ?</span
          >
          <router-link
            to="/register/fr/"
            id="show-signup"
            class="link text-primary font-15"
            >S'inscrire</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";

export default {
  name: "resetPassword",
  components: { Loader },

  data() {
    return {
      errorMessage: null,
      successMessage: null,
      email: null,
      firebaseAuth: myApp.auth(),
      loaderState: false,
    };
  },
  methods: {
    resetPassword() {
      if (this.email) {
        this.loaderState = true;
        const auth = this.firebaseAuth;
        auth
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.successMessage =
              "Le lien de réinitialisation du mot de passe a été envoyé. Veuillez vérifier votre boîte email.";
            this.email = null;
            this.loaderState = false;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.loaderState = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.error-msg {
  color: #f56c6c;
}
.login {
  background-image: url("/images/nature5.jpg");
}
.form-control {
  border-color: #55b94b70;
}
.form-control:focus {
  border-color: #55b94b;
}
.login {
  background: #55b94b70;
}
</style>
