<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <div class="card-list">
              <div v-if="allNotifs.length == 0" class="text-center">
                <img src="/images/notif.gif" height="100" width="100" alt="" />
                <br />
                <br />
                <p>Il n'y a pas de notification pour l'instant</p>
              </div>
              <div v-for="(item, index) in allNotifs" :key="index">
                <div class="col-sm-6 col-lg-12">
                  <router-link :to="item.res_link" class="link-card-style">
                    <div class="card p-1 notif-card bg-grey">
                      <div class="d-flex align-items-center">
                        <span class="stamp stamp-md bg-grey mr-2">
                          <i
                            v-if="!item.read"
                            class="icon-speech unread-notif"
                          ></i>
                          <i
                            v-if="item.read"
                            class="icon-speech read-notif"
                          ></i>
                        </span>
                        <div class="w-100">
                          <h5
                            class="mb-1"
                            @click="
                              clickToViewNotif(item.id, item.users_viewed)
                            "
                          >
                            <span v-if="item.user_triggered_id != userId"
                              ><b
                                >{{ item.user_triggered_name }} a partagé une
                                collection des ressources :
                                <small style="text-decoration: underline"
                                  >Cliquez pour voir</small
                                ></b
                              >
                            </span>
                            <span v-if="item.user_triggered_id == userId"
                              ><b
                                >Vous avez partagé une ressource :
                                <small style="text-decoration: underline"
                                  >Cliquez pour voir</small
                                ></b
                              >
                            </span>
                          </h5>
                          <small class="text-muted small">{{
                            daysDiff["value" + index]
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import * as fb from "firebase/app";

export default {
  name: "NotifsPage",
  components: { Loader },

  data() {
    return {
      notifsRef: myApp.firestore().collection("notifications"),
      loaderState: false,
      allNotifs: [],
      daysDiff: {},
      userId: this.$store.state.currentUserInfo.userId,
    };
  },
  methods: {
    timestampDiff(current, old, index) {
      let difference = current - old;
      let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

      switch (true) {
        case daysDifference == 0:
          this.daysDiff["value" + index] = `il y a quelques temps`;
          break;
        case daysDifference < 28:
          this.daysDiff["value" + index] = `Il y a ${daysDifference} jours`;
          break;
        case daysDifference > 28:
          this.daysDiff["value" + index] = new Date(old).toLocaleDateString(
            "fr-FR"
          ); //Timestamp to Date
          break;
      }
    },

    clickToViewNotif(collectionId, viewsArr) {
      let viewsArr2 = viewsArr;
      if (!viewsArr2.includes(this.userId)) {
        viewsArr2.push(this.userId);
        this.notifsRef
          .doc(collectionId)
          .update({ users_viewed: viewsArr2 })
      }
    },
  },

  created() {
    this.loaderState = true;
    // Getting notifications from DB
    let obj = {};
    this.notifsRef.orderBy("timestamp", "desc").onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        this.allNotifs = [];
        let index = 0;
        snapshot.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          obj.res_link = "/public-resource-view/fr/" + obj.res_link_id;
          const getCurrentTimestamp = fb.firestore.FieldValue.serverTimestamp();
          this.timestampDiff(getCurrentTimestamp, obj.timestamp, index);
          index++;
          obj.read = false;
          if (obj.users_viewed.includes(this.userId)) obj.read = true;
          this.allNotifs.push(obj);
        });
        this.loaderState = false;
      }

      this.loaderState = false;
    });
  },
};
</script>

<style scoped>
.link-card-style {
  color: #000;
  text-decoration: none;
}
.notif-page {
  min-height: 100vh;
}
.bg-green {
  background-color: #66cc9980;
}
.notif-card {
  border: 1px solid #dee2e6;
  margin: 10px;
}
.notif-card:hover {
  background-color: #66cc9952;
  cursor: pointer;
}
.page-inner-2 {
  color: #fafafa;
}
.bg-grey {
  background-color: #f3f2ef;
}
.fa-comment-alt {
  color: #8d9498;
}
.username {
  color: #2d3f5a;
  font-size: 15px !important;
  font-weight: bold !important;
  word-break: break-word;
}
.page-inner-2 {
  background-color: #fff;
}
.info-user {
  padding-left: 20px;
}
@media screen and (min-width: 991px) {
  .card-body {
    margin: auto;
    width: 900px;
  }
}
.item-list:hover {
  background: #f1f1f187;
}
.unread-notif {
  color: #66cc99;
}
.read-notif {
  color: #8d9498;
}
.small {
  font-size: 14px;
}
</style>
