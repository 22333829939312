<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{{ collectionName }}</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <p class="text-success">{{ successMessage }}</p>
                  <form @submit.prevent method="POST">
                    <label class="share-to pl-3">Partager à</label>
                    <div class="form-group">
                      <div class="input-icon">
                        <span class="input-icon-addon">
                          <i class="icon-user"></i>
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="resourceToShare.res_receiver_email"
                          placeholder="Entrez l'adresse e-mail de l'utilisateur"
                        />
                      </div>
                    </div>
                  </form>

                  <div class="d-flex">
                    <smallLoader class="mt-2" v-if="smLoad" />
                    <div
                      class="avatar"
                      v-if="resourceToShare.res_receiver_username != ''"
                    >
                      <img
                        :src="imageUrl || '/images/user-image.png'"
                        alt=""
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div
                      class="info-user pl-2 pb-3"
                      v-if="resourceToShare.res_receiver_username != ''"
                    >
                      <div class="username font-weight-bold">
                        {{ resourceToShare.res_receiver_username }}
                      </div>
                      <div class="status">
                        {{ resourceToShare.res_receiver_email }}
                      </div>
                    </div>
                  </div>

                  <div class="card-action pl-2">
                    <router-link to="/home" class="btn btn-danger"
                      >Annuler</router-link
                    >
                    <button
                      class="btn btn-success ml-2"
                      v-if="!isUserFound"
                      @click="searchUser()"
                    >
                      Rechercher
                    </button>
                    <button
                      class="btn btn-success ml-2"
                      v-if="
                        isUserFound &&
                        resourceToShare.res_receiver_email !=
                          this.$store.state.currentUserInfo.email
                      "
                      @click="isSharedWith()"
                    >
                      Partagez maintenant
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="card-title fw-mediumbold">
                    Partagée avec des personnes
                  </div>
                  <div class="separator-dashed"></div>

                  <div class="card-list">
                    <smallLoader class="mt-2" v-if="smLoad2" />
                    <div
                      class="item-list"
                      v-for="(user, index) in all_users"
                      :key="index"
                    >
                      <div class="avatar">
                        <img
                          :src="
                            user.res_receiver_imageUrl ||
                            '/images/user-image.png'
                          "
                          alt="user image"
                          class="avatar-img rounded-circle"
                        />
                      </div>
                      <div class="info-user ml-3">
                        <div class="username">
                          {{ user.res_receiver_username }}
                        </div>
                        <div class="status">{{ user.res_receiver_email }}</div>
                      </div>
                      <button class="badge soft-green-bg badge-pill mr-3">
                        <div class="status">
                          {{
                            new Date(user.shared_when).toLocaleDateString(
                              "fr-FR"
                            )
                          }}
                        </div>
                      </button>
                      <button
                        class="badge badge-danger badge-pill"
                        @click="removeUser(user.document_id)"
                      >
                        <i class="icon-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import smallLoader from "../../components/loader/smallLoader";
import SweetAlert from "../../classes/fr/messages";
import * as fb from "firebase/app";

export default {
  name: "ShareTo",
  components: { Loader, smallLoader },

  data() {
    return {
      usersRef: myApp.firestore().collection("users"),
      foldersRef: myApp.firestore().collection("folders"),
      sharedResourcesRef: myApp.firestore().collection("shared_resources"),
      collectionName: null,
      errorMessage: null,
      loaderState: false,
      smLoad: false,
      smLoad2: false,
      successMessage: null,
      isUserFound: false,
      col_id: null,
      all_users: [],
      imageUrl: null,
      resourceToShare: {
        res_sharer_id: null,
        res_sharer_email: "",
        res_sharer_username: "",
        res_receiver_id: "",
        res_receiver_email: "",
        res_receiver_username: "",
        res_receiver_imageUrl: "",
        collection_id: null,
        shared_when: null,
      },
    };
  },

  methods: {
    searchUser() {
      let obj = {};
      this.smLoad = true;
      this.usersRef
        .where("email", "==", this.resourceToShare.res_receiver_email)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              this.resourceToShare.res_receiver_id = obj.user_id;
              this.resourceToShare.res_receiver_username = obj.username;
              this.resourceToShare.res_receiver_email = obj.email;
              this.resourceToShare.res_receiver_imageUrl = obj.imageUrl;
              this.isUserFound = true;
              this.smLoad = false;
              this.imageUrl = obj.imageUrl;
            });
          } else {
            this.errorMessage =
              "Cet utilisateur n'existe pas dans le système. Veuillez vérifier votre adresse e-mail.";
            this.smLoad = false;
          }
        });
    },

    isSharedWith() {
      this.sharedResourcesRef
        .where(
          "res_receiver_email",
          "==",
          this.resourceToShare.res_receiver_email
        )
        .where("collection_id", "==", this.$route.params.id_collection)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            this.errorMessage =
              "L'utilisateur existe déjà. Vérifiez dans la liste des utilisateurs.";
          } else this.shareResourcesToUser();
        });
    },

    // Func used to share a collection of resources to or multiple users
    shareResourcesToUser() {
      this.isUserFound = false;
      this.sharedResourcesRef
        .add(this.resourceToShare)
        .then(() => {
          this.foldersRef
            .doc(this.col_id)
            .update({ shared: true })
            .then(() => {
              this.resourceToShare.res_receiver_id = null;
              this.resourceToShare.res_receiver_username = "";
              this.resourceToShare.res_receiver_email = "";
              this.resourceToShare.res_receiver_imageUrl = "";
              this.shared_when = null;
              this.successMessage = "Collection partagée";
            });
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },

    // This func get all users who are sharing a resource
    getUsersByResources() {
      this.smLoad2 = true;
      let obj = {};
      this.sharedResourcesRef
        .where("collection_id", "==", this.col_id)
        .orderBy("shared_when", "desc")
        .onSnapshot((snapshot) => {
          this.all_users = [];
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.document_id = doc.id;
              obj.shared_when = new Date(obj.shared_when);
              this.all_users.push(obj);
              this.smLoad2 = false;
            });
          }
          this.smLoad2 = false;
        });
    },

    removeUser(id) {
      SweetAlert.infoMessage(
        "Attention!",
        "Vous êtes sur le point de supprimer l'utilisateur. Il ne verra plus cette collection de ressources."
      ).then((result) => {
        if (result) {
          this.sharedResourcesRef
            .doc(id)
            .delete()
            .then(() => {
              if (this.all_users.length === 0) {
                this.foldersRef.doc(this.col_id).update({ shared: false });
              }
            });
        }
      });
    },
  },

  created() {
    this.resourceToShare.res_sharer_id =
      this.$store.state.currentUserInfo.userId;
    this.resourceToShare.res_sharer_email =
      this.$store.state.currentUserInfo.email;
    this.resourceToShare.res_sharer_username =
      this.$store.state.currentUserInfo.username;
    this.resourceToShare.collection_id = this.$route.params.id_collection;
    this.resourceToShare.shared_when =
      fb.firestore.FieldValue.serverTimestamp();

    this.col_id = this.$route.params.id_collection;

    this.foldersRef
      .doc(this.$route.params.id_collection)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.collectionName = doc.data().name;
        } else this.collectionName = "Aucun nom de collection.";
      });
    this.getUsersByResources();
  },
};
</script>
<style scoped>
.form-control {
  height: 35px !important;
}
.search-btn {
  position: relative;
  top: -15px;
}
.shared-to {
  font-size: 15px;
}
.share-to {
  font-weight: bold;
  font-size: 16px !important;
}
.card-title {
  font-size: 16px;
  font-weight: bold;
}
.card-header {
  background-color: #29ab8785 !important;
  font-weight: bold;
}
.badge {
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #ebecec40 !important;
}
ul li {
  font-size: 15px;
}
.list-element {
  border-right: 1px solid #00000082;
  padding-right: 20px;
  padding-left: 20px;
}
.soft-green-bg {
  background-color: #edfaf7;
}
.item-list:hover {
  background: #f3f2ef;
  cursor: pointer;
}
</style>
