<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <form
                    @submit.prevent="updateFolderName(collection.id)"
                    method="POST"
                    action="#"
                  >
                    <div class="form-group">
                      <label for="folder_name">Nom de la collection*</label>
                      <input
                        type="text"
                        class="form-control"
                        style="background: #f1f1f1"
                        placeholder="Modifier le nom de la collection"
                        id="folder_name"
                        name="folder_name"
                        v-model="collection.name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="description">Description*</label>
                      <textarea
                        class="form-control"
                        placeholder="Modifier la description ici ..."
                        style="background: #f1f1f1"
                        rows="5"
                        name="description"
                        id="description"
                        v-model="collection.description"
                      ></textarea>
                    </div>
                    <div class="form-check">
                      <label>Etat de la collection</label><br />
                      <label class="form-radio-label ml-3">
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="private"
                          value="private"
                          v-model="collection.status"
                        />
                        <i class="fa fa-lock pl-2"></i>
                        <span class="form-radio-sign pl-1">Privé</span>
                      </label>
                      <p v-if="collection.status === 'private'">
                        <i class="fa fa-info-circle pr-1"></i>Il n'y a que vous
                        qui pouvez la voir..
                      </p>
                      <p v-if="collection.status !== 'private'">
                        <i class="fa fa-info-circle pr-1"></i>Tout le monde peut
                        le voir.
                      </p>
                    </div>
                    <div class="card-action">
                      <div class="float-right">
                        <a href="#" @click="back()" class="btn btn-danger mr-2"
                          >Retour</a
                        >
                        <button class="btn btn-success">Mettre à jour</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "EditCollection",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      folderRef: myApp.firestore().collection("folders"),
      collection: {
        name: "",
        type: "folder",
        description: null,
        status: "private",
      },
      loaderState: false,
      errorMessage: null,
    };
  },
  methods: {
    // Update folder name or title
    // Ajouter une instruction pour vérifier si tous les champs sont renseignés
    updateFolderName(collection_id) {
      if (this.collection.name !== "" && this.collection.description !== "") {
        this.loaderState = true;
        this.folderRef
          .doc(collection_id)
          .update(this.collection)
          .then(() => {
            this.loaderState = false;
            this.back();
          })
          .catch(() => {
            SweetAlert.errorMessage(
              "Impossible de mettre à jour la collection"
            );
            this.loaderState = false;
          });
      } else {
        this.errorMessage = "Assurez-vous que tous les champs sont remplis";
      }
    },
  },
  created() {
    //  this func retrieves a unique collection by id
    this.loaderState = true;
    let collection_id = this.$route.params.id;
    this.folderRef
      .doc(collection_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let obj = doc.data();
          this.collection.name = obj.name;
          this.collection.description = obj.description;
          this.collection.status = obj.status;
          this.collection.id = doc.id;
        }
        this.loaderState = false;
      });
  },
};
</script>

<style scoped></style>
