import SweetAlert from "../classes/en/messages";
import * as fb from "firebase/app";

export default {
  data() {
    return {
      inputType: "password",
    };
  },
  methods: {
    limitTag(event) {
      return event.target.value.substring(0, 20);
    },

    // This function truncs a multi line text in one line
    truncString(input, length) {
      if (input.length > length) input = input.substring(0, length) + "...";
      return input;
    },

    // This function formats tags to this way: (word)_(word)_(word)
    reformatTag(tag) {
      let regex = /^\s+$/;
      if (regex) tag = tag.split(" ").join("_");
      return tag.toLowerCase();
    },

    // This func displays password
    showPassword() {
      if (this.inputType === "password") this.inputType = "text";
      else this.inputType = "password";
    },

    // Copy link
    copyLinkFunc(index2, title, message) {
      const copyElement = document.querySelector("#copy-element" + index2);
      copyElement.setAttribute("type", "text");
      copyElement.select();

      try {
        document.execCommand("copy"); // TO DO : change this function
        SweetAlert.successMessage(title, message);
      } catch (err) {
        alert("Impossible de copier");
      }

      /* unselect the range */
      copyElement.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    // Requests to the DB
    getPublicCollectionOfUser(userInfo) {
      const userId = userInfo.user_id;
      this.folderRef
        .where("userId", "==", userId)
        .where("status", "==", "public")
        .get()
        .then((snapshot) => {
          let publicCol = 0;
          if (!snapshot.empty) {
            snapshot.forEach(() => {
              publicCol = publicCol + 1;
            });
          }
          userInfo.publicCol = publicCol;
          this.getPrivateCollectionOfUser(userInfo);
        });
    },

    getPrivateCollectionOfUser(userInfo) {
      const userId = userInfo.user_id;
      this.folderRef
        .where("userId", "==", userId)
        .where("status", "==", "private")
        .get()
        .then((snapshot) => {
          let privateCol = 0;
          if (!snapshot.empty) {
            snapshot.forEach(() => {
              privateCol = privateCol + 1;
            });
          }
          userInfo.privateCol = privateCol;
          this.users.push(userInfo);
          this.loaderState = false;
        });
    },

    saveNotifsInDB(id, res_id, username) {
      const timestamp = fb.firestore.FieldValue.serverTimestamp();
      if (id !== null && res_id !== null && username !== null) {
        this.notifsRef.add({
          user_triggered_id: id,
          user_triggered_name: username,
          users_deleted: [],
          users_viewed: [],
          timestamp: timestamp,
          res_link_id: res_id,
        });
      }
    },

    back() {
      this.$router.go(-1);
    },
  },
};
