<template>
  <div class="page-container">
    <div class="wrapper page-content">
      <div class="col-md-8">
        <div class="card card-invoice">
          <div class="card-header">
            <h2 class="welcome-msg">Hello!</h2>
          </div>
          <div class="card-footer">
            <div class="separator-solid"></div>
            <p class="text-muted mb-0">
              Votre compte a été créé avec succès. Nous avons envoyé dans votre
              boîte email un lien de confirmation. <br />
              Veuillez cliquer dessus pour activer votre compte Okacodehub.
              Après avoir activé votre compte, vous pouvez maintenant vous
              connecter. <br />
              <button class="btn" @click="sendEmailVerification()">
                Renvoyez le lien de confirmation
              </button>
              <router-link to="/" class="btn go-login text-danger"
                >Aller à la page de connexion</router-link
              >
            </p>
            <div class="separator-solid"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";

export default {
  name: "EmailVerification",
  data() {
    return {};
  },
  methods: {
    sendEmailVerification() {
      const user = myApp.auth().currentUser;
      if (user) {
        user
          .sendEmailVerification()
          .then(() => {
            alert("e-mail de vérification envoyé");
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    },
  },
};
</script>

<style>
.font-size-25 {
  font-size: 18px;
}
.container {
  padding: 30px;
}
.btn {
  padding: 5px;
  margin-top: 10px;
}
a.go-login {
  padding-left: 5px;
}
.welcome-msg {
  color: #000;
}
</style>
