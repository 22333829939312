<template>
  <div style="height: auto; min-height: 100%">
    <div
      style="
        text-align: center;
        width: 800px;
        margin-left: -400px;
        position: absolute;
        top: 30%;
        left: 50%;
      "
    >
      <h1
        style="
          margin: 0;
          font-size: 150px;
          line-height: 150px;
          font-weight: bold;
        "
      >
        404
      </h1>
      <h2 style="margin-top: 20px; font-size: 30px">Non trouvée</h2>
      <p>
        La ressource ou l'Url demandée n'a pas pu être trouvée dans ce serveur !
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
