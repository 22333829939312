//  SWEET ALERT MESSAGES
import swal from 'sweetalert';
export default class ModalMessages{

  // info
  static infoMessage(title, message){
    return 	swal(title, message, {
      icon: 'warning',
      buttons:{
        cancel: {
          text : 'Cancel',
          visible: true,
          className: 'btn btn-danger'
        },
        confirm: {
          text : 'Confirm',
          className : 'btn btn-success'
        }
        
      }
    });
  }
// Achived
  static archivedMessage(){
    return swal({
      title: "Archived!",
      icon : "success",
      buttons: {        			
        confirm: {
          className : 'btn btn-success'
        }
      },
    })
  }

// Success
  static successMessage(title, message){
    return swal(message, {
      title: title,
      icon : "success",
      buttons: {        			
        confirm: {
          className : 'btn btn-success'
        }
      },
    })
  }

  // Error 
  static errorMessage(message){
    return swal(message, {
      title: "Error!",
      icon : "error",
      buttons: {        			
        confirm: {
          className : 'btn btn-danger'
        }
      },
    })
  }

  // Display when we want to delete an element
  static deleteMessage(){
    return swal({
      title: 'Do you want to delete it?',
      text: "If deleted this element will no longer exist",
      icon: 'warning',
      buttons:{
        cancel: {
          text : 'Cancel',
          visible: true,
          className: 'btn btn-danger'
        },
        confirm: {
          text : 'Confirm',
          className : 'btn btn-success'
        },
      }
    })
  }

  // Appear when a element is deleted
  static deletedMessage(){
    return swal({
      title: 'Deleted!',
      text: "The element has been deleted!",
      icon: 'success',
      buttons : {
        confirm: {
          className : 'btn btn-success'
        }
      }
    })
  }
}