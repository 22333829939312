<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <!-- Customized Card -->
      <h4 class="page-title">Activité de l'utilisateur</h4>
      <div class="row">
        <div class="col-md-8">
          <div v-if="publicCollections.length === 0">
            <img src="/images/folder.gif" height="50" width="50" alt="" />
            <br />
            <br />
            <p>
              <b>{{ userName }}</b> n'a pas encore de collections en public.
            </p>
          </div>
          <div class="row">
            <div
              class="col-sm-3 col-lg-6"
              v-for="(collection, index) in publicCollections"
              :key="index"
            >
              <router-link
                :to="'/public-resource-view/fr/' + collection.id"
                class="card-link-block"
              >
                <div class="card">
                  <div class="px-3 pt-2">
                    <p
                      class="rs-title"
                      style="color: #003f75"
                      :title="collection.name"
                    >
                      {{ truncString(collection.name, 25) }}
                    </p>
                    <p>{{ truncString(collection.description, 30) }}</p>
                    <p class="p-icons">
                      <span v-if="ambassador" class="float-left"
                        ><i class="icon-folder-alt"></i
                      ></span>
                      <span v-if="!ambassador" class="float-left"
                        ><i class="fas fa-folder"></i
                      ></span>
                      <span class="float-right">
                        <i class="fa fa-eye"></i> {{ collection.views.length }}
                      </span>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card card-profile">
            <div class="card-header" style="background-color: #f2f2f2">
              <div class="profile-picture">
                <div class="avatar avatar-xl">
                  <img
                    :src="imageUrl"
                    alt="user image"
                    class="avatar-img rounded-circle"
                  />
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="user-profile text-center">
                <div class="name">
                  {{ userName || "Aucun nom d'utilisateur" }}
                </div>
                <div class="job">
                  {{ profile || "Aucune information de profil" }}
                </div>
                <div class="social-media">
                  <a
                    v-if="linkedinValue"
                    class="btn btn-info btn-sm btn-link"
                    target="_blank"
                    :href="linkedinValue"
                    :title="linkedinValue"
                  >
                    <span class="btn-label just-icon"
                      ><i class="fab fa-linkedin"></i>
                    </span>
                  </a>
                  <a
                    v-if="mediumValue"
                    class="btn btn-black btn-sm btn-link"
                    target="_blank"
                    :href="mediumValue"
                    :title="mediumValue"
                  >
                    <span class="btn-label just-icon"
                      ><i class="fab fa-medium"></i>
                    </span>
                  </a>
                  <a
                    v-if="githubValue"
                    class="btn btn-black btn-sm btn-link"
                    target="_blank"
                    :href="githubValue"
                    :title="githubValue"
                  >
                    <span class="btn-label just-icon"
                      ><i class="fab fa-github"></i>
                    </span>
                  </a>
                  <a
                    v-if="portofolioValue"
                    class="btn btn-danger btn-sm btn-link"
                    target="_blank"
                    :href="portofolioValue"
                    :title="portofolioValue"
                  >
                    <span class="btn-label just-icon"
                      ><i class="far fa-gem"></i>
                    </span>
                  </a>
                  <a
                    v-if="stackOverflowValue"
                    class="btn btn-warning btn-sm btn-link"
                    target="_blank"
                    :href="stackOverflowValue"
                    :title="stackOverflowValue"
                  >
                    <span class="btn-label just-icon"
                      ><i class="fab fa-stack-overflow"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row user-stats text-center">
                <div class="col">
                  <div class="number">{{ publicCol }}</div>
                  <div class="title">Ressource(s) publiques</div>
                </div>
                <div class="col">
                  <div class="number">{{ privateCol }}</div>
                  <div class="title">Ressource(s) privées</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card card-bg card-annoucement card-round"
            v-if="ambassador"
          >
            <div class="card-body text-center">
              <div class="card-opening">
                <i class="fas fa-certificate"></i>
                Okacode Hub Ambassadeur
              </div>
              <div class="card-desc">
                Oka {{ userName }}. Construisons une communauté solide ensemble.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Utilities from "../../mixins/utilities";
import Loader from "../../components/loader/loader";

export default {
  name: "ReachMeView",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      userName: null,
      profile: null,
      ambassador: false,
      publicCol: 0,
      privateCol: 0,
      imageUrl: "/images/user-image.png",
      folderRef: myApp.firestore().collection("folders"),
      userId: this.$store.state.currentUserInfo.userId,
      publicCollections: [],
      usersRef: myApp.firestore().collection("users"),
      socials: {},
      loaderState: false,
      linkedinValue: null,
      mediumValue: null,
      githubValue: null,
      stackOverflowValue: null,
      portofolioValue: null,
    };
  },
  methods: {
    getUserInformation(user_id) {
      this.usersRef
        .doc(user_id)
        .get()
        .then((doc) => {
          this.loaderState = true;
          if (doc.exists) {
            let obj = doc.data();
            this.userName = obj.username;
            this.profile = obj.profile;
            if (obj.imageUrl) this.imageUrl = obj.imageUrl;
            this.socials = obj.socials;
            this.ambassador = obj.ambassador;

            this.linkedinValue = obj.socials[0].value;
            this.mediumValue = obj.socials[1].value;
            this.githubValue = obj.socials[2].value;
            this.portofolioValue = obj.socials[3].value;
            this.stackOverflowValue = obj.socials[4].value;
            this.loaderState = false;
          }
        });
    },

    getUserPublicCollections(userId) {
      let obj = {}
      this.loaderState = true;
      this.folderRef
        .where("userId", "==", userId)
        .where("status", "==", "public")
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              doc.data().id = doc.id;
              this.publicCollections.push(obj);
              this.loaderState = false;
            });
          }
        });
    },
  },

  created() {
    const docId = this.$route.params.docId;
    const userId = this.$route.params.user_id;
    this.publicCol = this.$route.params.pubCol;
    this.privateCol = this.$route.params.privCol;
    this.getUserInformation(docId);
    this.getUserPublicCollections(userId);
  },
};
</script>

<style scoped>
.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.card:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 20%);
}
p {
  margin-bottom: 0 !important;
  line-height: none !important;
}
.p-h5 {
  color: #003f75 !important;
  padding-top: 10px !important;
}
.p-rs:hover {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px !important;
  transition: all 0.3s;
}
.p-rs {
  font-weight: 600;
  font-size: 15px;
}
.card-bg {
  background: #55b94b;
  color: #fff;
}
.p-icons i {
  color: #8d9498;
}
.card-link-block:hover {
  text-decoration: none;
}
.card-link-block {
  color: #2a2f5b;
}
</style>
