<template>
  <div class="loader"></div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.loader {
  border: 2px solid #3498db; /* Light grey */
  border-top: 2px solid rgb(177, 165, 165); /* Blue */
  border-radius: 50%;
  width: 27px;
  height: 27px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
