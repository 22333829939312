import Vue from "vue";
import Vuex from "vuex";
import myApp from "../firebase/init";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isConnected: false,
    currentUserInfo: {
      userId: null,
      username: null,
      email: null,
      imageUrl: "/images/user-image.png",
      profile: null,
      socials: {
        linkedin: null,
        medium: null,
        github: null,
        portofolio: null,
        stackOverflow: null,
      },
      ambassador: false,
    },
  },
});

myApp.auth().onAuthStateChanged((user) => {
  if (user) {
    store.state.isConnected = true;
    let usersRef = myApp.firestore().collection("users");

    // get connected user data form db
    let currentUser = myApp.auth().currentUser;
    store.state.currentUserInfo.userId = currentUser.uid;
    store.state.currentUserInfo.lastSignInTime =
      currentUser.metadata.lastSignInTime;

    usersRef
      .where("user_id", "==", store.state.currentUserInfo.userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            let obj = doc.data();
            store.state.currentUserInfo.username = obj.username;
            store.state.currentUserInfo.email = obj.email;

            // Get social profiles data
            store.state.currentUserInfo.socials.linkedin = obj.socials[0].value;
            store.state.currentUserInfo.socials.medium = obj.socials[1].value;
            store.state.currentUserInfo.socials.github = obj.socials[2].value;
            store.state.currentUserInfo.socials.portofolio =
              obj.socials[3].value;
            store.state.currentUserInfo.socials.stackOverflow =
              obj.socials[4].value;

            store.state.currentUserInfo.ambassador = obj.ambassador;
            if (obj.profile) store.state.currentUserInfo.profile = obj.profile;
            if (obj.imageUrl)
              store.state.currentUserInfo.imageUrl = obj.imageUrl;
            else if (obj.imageUrl == null)
              store.state.currentUserInfo.imageUrl = "/images/user-image.png";
          });
        }
      });
  }
});

export default store;
