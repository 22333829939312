<template>
  <div>
    <footer class="footer">
      <div class="container-fluid">
        <nav class="pull-left">
          <ul class="nav">
            <li class="nav-item">
              <a
                class="nav-link"
                target="__blank"
                href="/docs/okacodehub-documentation.pdf"
              >
                Documentation
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright ml-auto">
          POWERED BY OKACODE © 2023, tous droits reservés.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {};
  },
};
</script>

<style scoped>
a {
  color: #000;
}
</style>
