<template>
  <div class="login">
    <div class="wrapper wrapper-login">
      <Loader v-if="loaderState" />
      <div class="container container-login">
        <div
          class="alert alert-warning"
          role="alert"
          v-if="errorMessage != null"
        >
          Veuillez vérifier que vous avez validé le lien de confirmation envoyé
          à votre boîte aux lettres avant de vous connecter.
        </div>
        <span class="error-msg">{{ errorMessage }}</span>
        <h3 class="text-center">
          <img src="/images/logo.png" alt="logo okacode hub" />
        </h3>
        <form @submit.prevent="signIn">
          <div class="login-form">
            <div class="form-group form-floating-label">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Saisir l'adresse email"
                v-model="user.email"
                class="form-control input-border-bottom"
              />
            </div>
            <div class="form-group form-floating-label">
              <input
                :type="inputType"
                id="password"
                name="password"
                class="form-control input-border-bottom"
                v-model="user.password"
                placeholder="Saisir le mot de passe"
              />
              <div class="show-password" @click="showPassword">
                <i class="icon-eye"></i>
              </div>
            </div>
            <div class="row form-sub m-0">
              <router-link
                to="/reset_password/fr/"
                class="float-right text-primary font-15"
                >Mot de passe oublié?</router-link
              >
            </div>
            <div class="form-action mb-3">
              <button class="btn btn-success font-weight-bold">
                Se connecter
              </button>
            </div>
            <div @click="loginViaGoogle()" class="g-style text-center">
              <img src="/images/google.png" alt="google icon" class="mr-3" />
              Se connecter via Google
            </div>
          </div>
        </form>
        <div class="login-account">
          <span class="msg font-15" style="padding-right: 5px"
            >Pas de compte ?</span
          >
          <router-link
            to="/register/fr/"
            id="show-signup"
            class="link text-primary font-15"
            >Créer un compte</router-link
          >
        </div>
        <div class="login-account">
          <router-link
            to="/fr/"
            id="show-signup"
            class="link text-primary font-15"
          >
            <i class="fa fa-arrow-left"></i>
            Retour à la page d'acceuil
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import * as fb from "firebase/app";

import Loader from "../../components/loader/loader";
import utilities from "../../mixins/utilities";

export default {
  name: "Login",
  components: { Loader },
  mixins: [utilities],
  data() {
    return {
      errorMessage: null,
      loaderState: false,
      user: {
        email: "",
        password: "",
      },
      usersRef: myApp.firestore().collection("users"),
    };
  },
  methods: {
    // Login the user to the system
    signIn() {
      this.loaderState = true;
      myApp
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {
          const isEmailVerified = myApp.auth().currentUser.emailVerified;
          if (isEmailVerified) {
            this.$router.replace({ name: "Home" });
            this.loaderState = false;
          } else {
            this.sendEmailVerification();
            this.loaderState = false;
          }
        })
        .catch(() => {
          this.errorMessage =
            "Erreur : l'utilisateur n'existe pas ou soit vous avez entrer des mauvaises informations.";
          this.loaderState = false;
        });
    },

    sendEmailVerification() {
      const user = myApp.auth().currentUser;
      user
        .sendEmailVerification()
        .then(() => {
          this.$router.replace({ name: "ConfirmEmailAddress_FR" });
        })
        .catch(() => {
          alert(
            "Une erreur est survenue : impossible d'envoyer l'email de confirmation"
          );
        });
    },

    loginViaGoogle() {
      const provider = new fb.auth.GoogleAuthProvider();

      myApp
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.loaderState = true;
          this.usersRef
            .where("user_id", "==", result.user.uid)
            .get()
            .then((snapshot) => {
              if (snapshot.size == 0) {
                this.createUser(
                  result.user.displayName,
                  result.user.email,
                  result.user.uid,
                  result.user.photoURL
                ).then(() => {
                  this.$router.replace({ name: "Home" });
                });
              } else {
                this.$router.replace({ name: "Home" });
                this.loaderState = false;
              }
            });
        });
    },

    createUser(username, email, userId, imageUrl) {
      return this.usersRef.add({
        username: username,
        email: email,
        user_id: userId,
        imageUrl: imageUrl,
        profile: null,
        isAccountActive: true,
        ambassador: false,
        socials: [
          {
            name: "Linkedin",
            key: "linkedin",
            icon: "fab fa-linkedin",
            value: "",
          },
          {
            name: "Medium",
            key: "medium",
            icon: "fab fa-medium",
            value: "",
          },
          {
            name: "Github",
            key: "github",
            icon: "fab fa-github",
            value: "",
          },
          {
            name: "Portofolio",
            key: "portofolio",
            icon: "far fa-gem",
            value: "",
            active: true,
          },
          {
            name: "Stack Overflow",
            key: "stack-overflow",
            icon: "fab fa-stack-overflow",
            value: "",
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.error-msg {
  color: #f56c6c;
  display: block;
  text-align: center;
}
.login {
  background: #55b94b70;
}
.form-control {
  border-color: #55b94b70;
}
.form-control:focus {
  border-color: #55b94b;
}
.g-style {
  font-weight: bold;
  line-height: 40px !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #ebecec;
  font-size: 15px;
  width: 300px !important;
  margin: auto;
}
.g-style:hover {
  cursor: pointer;
  background: #091e420f;
}
</style>
