<template>
  <div class="login">
    <div class="wrapper wrapper-login">
      <div class="container container-signup">
        <Loader v-if="loaderState" />
        <span class="error-msg">{{ errorMessage }}</span>
        <h3 class="text-center">
          <img src="/images/logo.png" alt="logo okacode hub" />
        </h3>
        <form @submit.prevent="register">
          <div class="login-form">
            <div class="form-group form-floating-label">
              <input
                id="username"
                name="username"
                type="text"
                class="form-control input-border-bottom"
                placeholder="Saisir le nom d'utilisateur"
                v-model="username"
              />
            </div>
            <div class="form-group form-floating-label">
              <input
                id="email"
                name="email"
                type="email"
                class="form-control input-border-bottom"
                placeholder="Saisir l'adresse email"
                v-model="email"
              />
            </div>
            <div class="form-group form-floating-label">
              <input
                id="password"
                name="password"
                :type="inputType"
                class="form-control input-border-bottom"
                placeholder="Saisir le mot de passe"
                v-model="password"
              />
              <div class="show-password" @click="showPassword">
                <i class="icon-eye"></i>
              </div>
            </div>
            <div class="form-group form-floating-label">
              <input
                id="confirmpassword"
                name="confirmpassword"
                :type="inputType"
                class="form-control input-border-bottom"
                placeholder="Confirmer le mot de passe"
                v-model="confirmedPassword"
              />
              <div class="show-password" @click="showPassword()">
                <i class="icon-eye"></i>
              </div>
            </div>
            <div class="form-action mb-3">
              <button class="btn btn-success font-weight-bold">
                S'inscrire
              </button>
            </div>
            <div @click="registerViaGoogle()" class="g-style text-center">
              <img src="/images/google.png" alt="google icon" class="mr-3" />
              Continuer avec Google
            </div>
            <div class="login-account">
              <span class="msg font-15" style="padding-right: 5px"
                >Avez-vous un compte ?</span
              >
              <router-link
                to="/login/fr/"
                id="show-signup"
                class="link text-primary font-weight-bold font-15"
                >Se connecter</router-link
              >
            </div>
            <div class="form-action">
              <router-link
                to="/fr/"
                id="show-signup"
                class="link text-primary font-15"
              >
                <i class="fa fa-arrow-left"></i>
                Retour à la page d'acceuil
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import * as fb from "firebase/app";

import Loader from "../../components/loader/loader";
import utilities from "../../mixins/utilities";

export default {
  name: "Register",
  components: { Loader },
  mixins: [utilities],
  data() {
    return {
      username: "",
      email: "",
      password: "",
      confirmedPassword: "",
      loaderState: false,
      firebaseAuth: myApp.auth(),
      usersRef: myApp.firestore().collection("users"),
      errorMessage: null,
    };
  },
  methods: {
    register() {
      if (
        this.username &&
        this.email &&
        this.password &&
        this.confirmedPassword
      ) {
        if (this.password === this.confirmedPassword) {
          this.firebaseAuth
            .createUserWithEmailAndPassword(this.email, this.password)
            .then((data) => {
              this.loaderState = true;
              let userId = data.user.uid;

              // Save user info in firestore
              let username = this.username.toLowerCase();

              this.createUser(username, this.email, userId, null)
                .then(() => {
                  this.loaderState = false;

                  this.sendEmailVerification(); // Send Email Verification

                  // Reset register fields
                  this.username = null;
                  this.email = null;
                  this.password = null;
                  this.confirmedPassword = null;
                })
                .catch(() => {
                  this.errorMessage =
                    "Erreur lors de l'envoi de l'email de vérification";
                  this.loaderState = false;
                });
            })
            .catch(() => {
              this.errorMessage =
                "Erreur : Impossible de créer le compte, vérifier que vous avez bien remplis vos informations.";
              this.loaderState = false;
            });
        } else {
          this.errorMessage = "Mots de passe non identiques";
        }
      } else {
        this.errorMessage = "Certains champs sont vides";
      }
    },

    registerViaGoogle() {
      const provider = new fb.auth.GoogleAuthProvider();

      myApp
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.createUser(
            result.user.displayName,
            result.user.email,
            result.user.uid,
            result.user.photoURL
          ).then(() => {
            this.$router.replace({ name: "Home" });
          });
        });
    },

    createUser(username, email, userId, imageUrl) {
      return this.usersRef.add({
        username: username,
        email: email,
        user_id: userId,
        imageUrl: imageUrl,
        profile: null,
        isAccountActive: true,
        ambassador: false,
        socials: [
          {
            name: "Linkedin",
            key: "linkedin",
            icon: "fab fa-linkedin",
            value: "",
          },
          {
            name: "Medium",
            key: "medium",
            icon: "fab fa-medium",
            value: "",
          },
          {
            name: "Github",
            key: "github",
            icon: "fab fa-github",
            value: "",
          },
          {
            name: "Portofolio",
            key: "portofolio",
            icon: "far fa-gem",
            value: "",
            active: true,
          },
          {
            name: "Stack Overflow",
            key: "stack-overflow",
            icon: "fab fa-stack-overflow",
            value: "",
          },
        ],
      });
    },

    // Send email verification to the user
    sendEmailVerification() {
      const user = this.firebaseAuth.currentUser;
      user
        .sendEmailVerification()
        .then(() => {
          this.$router.replace({ name: "ConfirmEmailAddress" });
        })
        .catch(() => {
          alert("Un mail de vérification a été envoyé");
        });
    },
  },
};
</script>

<style scoped>
.login {
  background: #55b94b70;
}
.error-msg {
  color: #f56c6c;
  display: block;
  text-align: center;
}
.btn-login {
  font-size: 15px !important;
}
.form-control {
  border-color: #55b94b70;
}
.form-control:focus {
  border-color: #55b94b;
}
.g-style {
  font-weight: bold;
  line-height: 40px !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #ebecec;
  font-size: 15px;
  width: 300px !important;
  margin: auto;
}
.g-style:hover {
  cursor: pointer;
  background: #091e420f;
}
</style>
