<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <form
                    @submit.prevent="createResource()"
                    method="POST"
                    action="#"
                  >
                    <div class="form-group">
                      <label for="text2">Lien de la ressource*</label>
                      <input
                        type="text"
                        class="form-control"
                        id="folder_name"
                        name="folder_name"
                        v-model="data.content"
                        placeholder="Ex: www.myApp.com"
                      />
                    </div>
                    <div class="form-group">
                      <label for="tag">Ajouter un tag*</label>
                      <input
                        type="text"
                        class="form-control"
                        id="resource_tag"
                        name="resource_tag"
                        @input="tagSize($event)"
                        v-model="data.tag"
                        placeholder="Entrez le tag ici"
                      />
                    </div>
                    <div class="form-group">
                      <label for="comment">Description*</label>
                      <textarea
                        class="form-control"
                        placeholder="Ajoutez la description ici"
                        name="description"
                        id="description"
                        v-model="data.description"
                        rows="5"
                      ></textarea>
                    </div>
                    <div class="card-action">
                      <div class="float-right">
                        <a href="#" @click="back()" class="btn btn-danger mr-2"
                          >Annuler</a
                        >
                        <button class="btn btn-success">Enregistrer</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/loader/loader";
import myApp from "../../firebase/init";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";
import * as fb from "firebase/app";

export default {
  name: "NewResource",
  components: { Loader },
  mixins: [Utilities],
  data() {
    return {
      dataRef: myApp.firestore().collection("links"),
      data: {
        content: "",
        description: "",
        tag: null,
        type: "link",
      },
      loaderState: false,
      errorMessage: "",
    };
  },
  methods: {
    tagSize(event) {
      this.data.tag = this.limitTag(event).toLowerCase().trim();
    },

    // Creates resources
    createResource() {
      if (this.data.name !== "" && this.data.description && this.data.tag) {
        this.loaderState = true;
        this.data.timestamp = fb.firestore.FieldValue.serverTimestamp();
        this.data.userId = this.$store.state.currentUserInfo.userId;
        this.dataRef
          .add(this.data)
          .then(() => {
            this.data.content = "";
            this.data.description = "";
            this.data.tag = null;
            this.back();
          })
          .catch(() => {
            SweetAlert.errorMessage("Impossible de créer une resource");
            this.loaderState = false;
          });
      } else {
        this.errorMessage = "Assurez-vous que tous les champs sont remplis";
      }
    },
  },
  created() {
    // associate folderId to the data
    this.data.folderId = this.$route.params.ref;
  },
};
</script>

<style scoped>
.col-image {
  width: 500px;
}
</style>
