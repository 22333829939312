<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <form
                    @submit.prevent="updateResource()"
                    method="POST"
                    action="#"
                  >
                    <div class="form-group">
                      <label for="text2">Lien de la ressource*</label>
                      <input
                        type="text"
                        class="form-control"
                        style="background: #f1f1f1"
                        id="folder_name"
                        name="folder_name"
                        v-model="data.content"
                        placeholder="Mettre à jour le lien de la ressource"
                      />
                    </div>
                    <div class="form-group">
                      <label for="tag">Ajouter un tag*</label>
                      <input
                        type="text"
                        class="form-control"
                        style="background: #f1f1f1"
                        id="resource_tag"
                        name="resource_tag"
                        @input="tagSize($event)"
                        v-model="data.tag"
                        placeholder="Mettre à jour le tag"
                      />
                    </div>
                    <div class="form-group">
                      <label for="comment">Description*</label>
                      <textarea
                        class="form-control"
                        placeholder="Modifier la description ici"
                        style="background: #f1f1f1"
                        name="description"
                        id="description"
                        v-model="data.description"
                        rows="5"
                      ></textarea>
                    </div>
                    <div class="card-action">
                      <div class="float-right">
                        <a href="#" @click="back()" class="btn btn-danger mr-2"
                          >Retour</a
                        >
                        <button class="btn btn-success">Mettre à jour</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/loader/loader";
import myApp from "../../firebase/init";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "NewResource",
  components: { Loader },
  mixins: [Utilities],
  data() {
    return {
      dataRef: myApp.firestore().collection("links"),
      data: {
        content: "",
        description: "",
        tag: null,
        type: "link",
      },
      loaderState: false,
      errorMessage: "",
    };
  },
  methods: {
    // Update the data link in DB
    updateResource() {
      if (this.data.content !== "" && this.data.description && this.data.tag) {
        this.loaderState = true;
        this.dataRef
          .doc(this.data.id)
          .update(this.data)
          .then(() => {
            // Redirect to the collection where the data is located
            this.back();
            this.loaderState = false;
          })
          .catch(() => {
            SweetAlert.errorMessage("Impossible de mettre à jour la resource");
            this.loaderState = false;
          });
      } else {
        this.errorMessage = "Assurez-vous que tous les champs sont remplis";
      }
    },

    tagSize(event) {
      this.data.tag = this.limitTag(event);
    },
  },
  created() {
    //  this func retrieves a unique resource by id
    this.loaderState = true;
    let resource_id = this.$route.params.id; //  This is resource id
    this.dataRef
      .doc(resource_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let obj = doc.data();
          this.data.content = obj.content;
          this.data.description = obj.description;
          this.data.tag = obj.tag;
          this.data.id = doc.id;
        }
        this.loaderState = false;
      });
  },
};
</script>

<style scoped></style>
