<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="page-inner">
      <div class="collapse mb-5" id="search-nav">
        <form class="navbar-left navbar-form nav-search mr-md-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="submit" @click.prevent class="btn btn-search pr-1">
                <i class="icon-magnifier search-icon"></i>
              </button>
            </div>
            <input
              type="text"
              placeholder="Rechercher une collection ..."
              class="form-control"
              v-model="searchItem"
              @input="searchElement"
            />
          </div>
        </form>
      </div>
      <div class="row row-projects">
        <div
          v-if="searchElement().length === 0"
          class="text-center"
          style="margin: auto"
        >
          <img src="/images/glass_hour.gif" height="100" width="100" alt="" />
          <br />
          <br />
          <p>
            Aucune collection publique pour le moment. Soyez le premier à
            partager une collection avec la communauté.
          </p>
        </div>
        <div
          class="col-sm-3 col-lg-3"
          v-for="(collection, index) in searchElement()"
          :key="index"
        >
          <router-link
            :to="'/public-resource-view/fr/' + collection.id"
            class="card-link-block"
          >
            <div class="card">
              <div class="card-body pt-2">
                <h5 class="mb-1 p-h5">@{{ truncString(collection.user, 20) }}</h5>
                <p
                  class="rs-title"
                  style="color: #003f75"
                  :title="collection.name"
                >
                  {{ truncString(collection.name, 25) }}
                </p>
                <p class="p-icons">
                  <span class="float-left"
                    ><i class="icon-folder-alt"></i
                  ></span>
                  <span class="float-right">
                    <i class="fa fa-eye"></i> {{ collection.views.length }}
                  </span>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "../../mixins/utilities";
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";

export default {
  name: "AllPublicResources",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      loaderState: false,
      folderRef: myApp.firestore().collection("folders"),
      usersRef: myApp.firestore().collection("users"),
      searchItem: null,
      collections: [],
      userId: this.$store.state.currentUserInfo.userId,
    };
  },

  methods: {
    searchElement() {
      return this.collections.filter(
        (data) =>
          !this.searchItem ||
          data.name.toLowerCase().includes(this.searchItem.toLowerCase())
      );
    },

    getUserCreatedRessource(userId, obj) {
      this.usersRef
        .where("user_id", "==", userId)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj.user = doc.data().username;
              this.collections.push(obj);
            });
          }
        });
    },
  },

  created() {
    this.loaderState = true;
    this.searchItem = null;
    this.folderRef
      .where("status", "==", "public")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            this.getUserCreatedRessource(obj.userId, obj);
          });
        }
        this.loaderState = false;
      });
  },
};
</script>

<style scoped>
.p-h5 {
  background: #66cc9980;
  /* background: #f8f8f8; */
  border-bottom: 1px solid #1111111c;
  color: #000;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
}
.card-body {
  padding: 0 !important;
}
.rs-title {
  padding-left: 9px;
  padding-right: 9px;
  font-size: 15px;
}
.card {
  border: 1px solid #2021241f;
}
.card:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 20%);
}
.bloc-height {
  height: 60px;
}
.p-icons {
  padding-left: 9px;
  padding-right: 9px;
}
.card-link-block:hover {
  text-decoration: none;
}
.card-link-block .p-icons {
  color: #2a2f5b;
}
</style>
