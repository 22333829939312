//  SWEET ALERT MESSAGES
import swal from 'sweetalert';
export default class ModalMessages{

  // info
  static infoMessage(title, message){
    return 	swal(title, message, {
      icon: 'warning',
      buttons:{
        cancel: {
          text : 'Annuler',
          visible: true,
          className: 'btn btn-danger'
        },
        confirm: {
          text : 'Confirmer',
          className : 'btn btn-success'
        }
        
      }
    });
  }
// Achived
  static archivedMessage(){
    return swal({
      title: "Archivé!",
      icon : "success",
      buttons: {        			
        confirm: {
          className : 'btn btn-success'
        }
      },
    })
  }

// Success
  static successMessage(title, message){
    return swal(message, {
      title: title,
      icon : "success",
      buttons: {        			
        confirm: {
          className : 'btn btn-success'
        }
      },
    })
  }

  // Error 
  static errorMessage(message){
    return swal(message, {
      title: "Erreur!",
      icon : "error",
      buttons: {        			
        confirm: {
          className : 'btn btn-danger'
        }
      },
    })
  }

  // Display when we want to delete an element
  static deleteMessage(){
    return swal({
      title: 'Voulez-vous le supprimer ? ',
      text: "Si cet élément est supprimé, il n'existera plus",
      icon: 'warning',
      buttons:{
        cancel: {
          text : 'Annuler',
          visible: true,
          className: 'btn btn-danger'
        },
        confirm: {
          text : 'Confirmer',
          className : 'btn btn-success'
        },
      }
    })
  }

  // Appear when a element is deleted
  static deletedMessage(){
    return swal({
      title: 'Supprimé!',
      text: "L'élément a été supprimé !",
      icon: 'success',
      buttons : {
        confirm: {
          className : 'btn btn-success'
        }
      }
    })
  }
}