<template>
  <div class="container">
    <Loader v-if="loaderState" />
    <div class="panel-header">
      <div class="page-inner py-5 page-bg">
        <div
          class="d-flex align-items-left align-items-md-center flex-column flex-md-row"
        >
          <div>
            <h2 class="pb-2 fw-bold community-title">Notre communauté</h2>
            <p style="color: #fff; font-size: 18px">
              Découvrez notre communauté
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-inner page-inner-2">
      <div class="row">
        <div v-if="users.length == 0" class="text-center" style="margin: auto">
          <img src="/images/tool.gif" height="100" width="100" alt="" /> <br />
          <br />
          <p>Aucun utilisateur dans la page communauté.</p>
        </div>
        <div class="col-md-12">
          <div>
            <div class="card-body">
              <div class="card-list">
                <div
                  class="item-list"
                  style="border-bottom: 1px solid #c3c8cb"
                  v-for="(user, index) in users"
                  :key="index"
                >
                  <div class="avatar">
                    <img
                      :src="user.imageUrl"
                      alt="avatar"
                      class="avatar-img rounded-circle"
                    />
                  </div>
                  <div class="info-user">
                    <div class="username">@{{ user.username }}</div>
                    <div class="status">
                      {{ user.profile }}
                    </div>
                  </div>
                  <div class="status">
                    Privé ({{ user.privateCol || 0 }})
                    <i class="icon-folder icon-position"></i>
                  </div>
                  <div class="status">
                    Public ({{ user.publicCol || 0 }})
                    <i class="icon-folder icon-position"></i>
                  </div>

                  <router-link
                    :to="{
                      name: 'ReachMeView_FR',
                      params: {
                        docId: user.id,
                        user_id: user.user_id,
                        pubCol: user.publicCol,
                        privCol: user.privateCol,
                      },
                    }"
                    class="btn btn-view btn-success"
                  >
                    Découvrir
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "../../mixins/utilities";
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";

export default {
  name: "Community",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      loaderState: false,
      folderRef: myApp.firestore().collection("folders"),
      usersRef: myApp.firestore().collection("users"),
      userId: this.$store.state.currentUserInfo.userId,
      sharedCollectionRef: myApp.firestore().collection("shared_resources"),
      users: [],
    };
  },

  methods: {},

  created() {
    this.loaderState = true;
    this.usersRef
      .where("isAccountActive", "==", true)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          // this.collections = [];
          snapshot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            if (!obj.imageUrl) obj.imageUrl = "/images/user-image.png";
            if (!obj.profile) obj.profile = "Aucune info";

            this.getPublicCollectionOfUser(obj);
          });
        } else this.loaderState = false;
      });
  },
};
</script>

<style scoped>
/* #202B40  #141C26 color for the darkmode*/
/* error message red #F27979 */
.panel-header {
  background: #038c8c;
  padding: 5px;
}
.page-bg {
  background: url("/images/good-bg-2.png") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
}
.username {
  color: #2d3f5a;
  font-size: 15px !important;
  font-weight: bold !important;
  word-break: break-word;
}
.status {
  font-size: 14px !important;
  font-weight: 600;
  word-break: break-word;
  padding-right: 20px; 
  margin-bottom: -14px
  
}
.page-inner-2 {
  background-color: #fff;
}
.btn-view {
  margin-right: 13px;
  color: #fff;
  background: #efefef;
}
.btn-view:hover {
  border: 1px solid #575962;
}
.community-title {
  color: #fff;
  font-size: 2rem;
}
.info-user {
  padding-left: 20px;
}
@media screen and (min-width: 991px) {
  .card-body {
    margin: auto;
    width: 900px;
  }
}
@media screen and (max-width: 991px) {
  .status {
    margin-top: 15px
  }
}
.item-list:hover {
  background: #f1f1f187;
}
.item-list {
  background: #f1f1f187;
  margin-bottom: 10px;
  padding-left: 10px;
  border-radius: 2px;
}
</style>
