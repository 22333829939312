<template>
  <div class="container container-full">
    <Loader v-if="loaderState" />
    <div class="page-inner page-inner-fill">
      <div class="mail-wrapper">
        <div class="page-content mail-content bg-white">
          <h3 class="pl-30 pt-30 h3-style">{{ collection_name }}</h3>
          <p class="pl-30 text-descr">{{ collection_descr }}</p>
          <div class="pl-30" v-if="datas.length === 0">
            Il n'y a pas de ressources dans cette collection ...
          </div>

          <div class="inbox-body" v-for="(data, index) in datas" :key="index">
            <div class="email-list">
              <div class="email-list-item">
                <div class="email-list-actions">
                  <div class="d-flex">
                    <div class="element-icon-style">
                      <img
                        :src="
                          'http://www.google.com/s2/favicons?domain=https://' +
                          data.content.split('//')[1]
                        "
                        alt="logo website"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="hidden"
                  :id="'copy-element' + index"
                  :value="data.content"
                />
                <span
                  class="icon-copy float-right"
                  @click="copyLinkFunc(index, 'Fait', 'Votre lien a été copié')"
                  ><i class="fa fa-link paperclip"></i
                ></span>
                <span class="link-style"
                  ><a
                    :href="data.content"
                    target="_blank"
                    :title="data.content"
                    >{{ truncString(data.content, 70) }}</a
                  ></span
                >
                <p>{{ data.description }}</p>
                <span class="tag-style">{{ reformatTag(data.tag) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "ShareToResourcesView",
  components: { Loader },
  mixins: [Utilities],
  data() {
    return {
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      sharedCollectionRef: myApp.firestore().collection("shared_resources"),
      userId: this.$store.state.currentUserInfo.userId,
      collection_descr: "",
      collection_name: "",
      datas: [],
      loaderState: false,
      folderId: null,
    };
  },
  methods: {
    // This func allows to get all datas in a given collection
    getAllDatasInsideFolder(docId) {
      // Getting all datas inside the collection
      let obj = {};
      this.dataRef
        .where("folderId", "==", docId)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          this.datas = [];
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              this.datas.push(obj);
              this.loaderState = false;
            });
          }
          this.loaderState = false;
        });
    },
  },
  created() {
    this.loaderState = true;
    this.folderId = this.$route.params.id_collection;
    let obj = {};
    this.sharedCollectionRef
      .where("collection_id", "==", this.folderId)
      .where("res_receiver_id", "==", this.userId)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          this.folderRef
            .doc(this.folderId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                obj = doc.data();
                this.collection_name = obj.name;
                this.collection_descr = obj.description;
                this.getAllDatasInsideFolder(doc.id);
              }
            });
        } else
          SweetAlert.errorMessage("Vous n'avez pas accès à cette ressource");
      });
  },
};
</script>
<style scoped>
.link-style {
  font-size: 15px;
}
h3 {
  font-weight: bold;
}
.pl-30 {
  padding-left: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.text-descr {
  font-size: 15px;
}
.mail-content {
  border: 1px solid #e3e6e8;
  margin: auto;
  width: 80%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
  border-radius: 4px 4px 0px 0px;
}
.inbox-body {
  margin: auto;
  width: 95% !important;
}
.tag-style {
  color: #fff;
  background-color: #f9826c;
  border: 1px solid #f9826c;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
}
p {
  font-size: 15px !important;
}
.icon-copy {
  font-size: 15px;
}
.h3-style {
  background-image: url("/images/visuel-2.jpg");
  padding: 40px 0px;
  padding-left: 15px;
  color: #fff;
  border-radius: 4px 4px 0px 0px;
  font-size: 28px;
}
.text-descr {
  padding: 10px;
  padding-left: 40px;
}
.icon-copy {
  font-size: 15px;
  background: #e3e6e8;
  padding: 3px 6px;
  border-radius: 3px 3px 0 0;
}
.icon-copy:hover {
  border-bottom: 2px solid #f9826c;
}
</style>
