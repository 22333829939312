<template>
  <div class="container container-full">
    <Loader v-if="loaderState" />
    <div class="page-inner page-inner-fill">
      <div class="page-with-aside mail-wrapper bg-white">
        <div class="page-aside" style="background-color: #fff">
          <div class="aside-header">
            <div class="title">Autres collections</div>
            <a
              class="btn btn-success toggle-email-nav"
              data-toggle="collapse"
              href="#email-app-nav"
              role="button"
              aria-expanded="false"
              aria-controls="email-nav"
            >
              <span class="btn-label">
                <i class="icon-folder"></i>
              </span>
              Afficher tout
            </a>
          </div>

          <div class="aside-nav collapse" id="email-app-nav">
            <ul class="nav">
              <li
                class="li-hover"
                v-for="(folder, index) in folders"
                :key="index"
                @click="
                  changeCollectionInfo(
                    folder.name,
                    folder.description,
                    folder.id
                  )
                "
              >
                <span class="badge float-right">{{ folder.nbDocs }}</span>
                <a :title="folder.name">
                  <i class="icon-folder"></i> {{ truncString(folder.name, 15) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-content mail-content">
          <h3
            class="font-weight-bold"
            style="padding-left: 30px; padding-top: 30px"
          >
            {{ currentfolderName }}
          </h3>

          <p style="padding-left: 30px">{{ folderDescr }}</p>
          <div class="inbox-body">
            <div class="mail-option">
              <div class="email-filters-left">
                <div class="btn-group">
                  <router-link
                    :to="'/new-resource/fr/' + folderId"
                    class="btn btn-success btn-mobile"
                    style="color: #fff"
                  >
                    Ajouter une ressource
                  </router-link>
                </div>
                <div class="btn-group">
                  <router-link
                    :to="'/publish-resource/fr/' + folderId"
                    class="btn btn-success2 color-white btn-mobile"
                    >Partager la collection</router-link
                  >
                </div>
                <div class="btn-group">
                  <router-link
                    class="btn btn-success2 color-white btn-mobile"
                    :to="'/edit-collection/fr/' + folderId"
                  >
                    Editer la collection
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="inbox-head col-lg-12">
            <form action="#" class="mb-4 mt-2">
              <div class="input-group">
                <input
                  type="text"
                  placeholder="Rechercher une ressource par mots clés ..."
                  class="form-control"
                  v-model="searchItem"
                  @input="searchElement"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="icon-magnifier search-icon"></i>
                  </span>
                </div>
              </div>
            </form>
            <div
              v-if="searchElement().length === 0"
              class="text-center"
              style="margin: auto"
            >
              <img src="/images/rocket.gif" height="100" width="100" alt="" />
              <br />
              <br />
              <p>
                Vous avez créé votre collection. Bien ! Maintenant, cliquez sur
                <b>"Ajouter une nouvelle ressource"</b> pour ajouter des
                ressources.
              </p>
            </div>
            <!-- <p v-if="searchElement().length === 0" class="pt-3"><i class="fa fa-info-circle"></i>You have created your collection. Good! Now click on <b>"Add new resource" button</b>  to fill it with resources.</p> -->
          </div>

          <div
            class="inbox-body"
            v-for="(data, index2) in searchElement()"
            :key="index2"
          >
            <div class="email-list">
              <div class="email-list-item">
                <div class="email-list-actions">
                  <div class="d-flex">
                    <div class="element-icon-style">
                      <img
                        :src="
                          'https://www.google.com/s2/favicons?domain=https://' +
                          data.content.split('//')[1]
                        "
                        alt="logo website"
                      />
                    </div>
                  </div>
                </div>
                <div class="email-list-detail">
                  <input
                    type="hidden"
                    :id="'copy-element' + index2"
                    :value="data.content"
                  />
                  <span
                    class="date float-right"
                    style="padding-left: 10px"
                    @click="deleteElement(index2, data.id)"
                    ><i class="icon-trash paperclip"></i
                  ></span>
                  <span
                    class="date float-right"
                    style="padding-left: 10px"
                    @click="
                      copyLinkFunc(index2, 'Fait', 'Votre lien a été copié')
                    "
                    ><i class="icon-link paperclip"></i
                  ></span>
                  <span class="date float-right" style="padding-left: 10px">
                    <router-link
                      style="color: #2d3f5a"
                      :to="'/edit-resource/fr/' + data.id"
                    >
                      <i class="icon-note paperclip"></i>
                    </router-link>
                  </span>
                  <span class="link-style"
                    ><a
                      :href="data.content"
                      target="_blank"
                      :title="data.content"
                      >{{ truncString(data.content, linkSize) }}</a
                    ></span
                  >
                  <p>{{ data.description }}</p>
                  <span class="tag-style">{{ reformatTag(data.tag) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myApp from "../../firebase/init";
import Loader from "../../components/loader/loader";
import Utilities from "../../mixins/utilities";
import SweetAlert from "../../classes/fr/messages";

export default {
  name: "ViewResources",
  components: { Loader },
  mixins: [Utilities],

  data() {
    return {
      searchItem: null,
      loaderState: false,
      datas: [],
      folderRef: myApp.firestore().collection("folders"),
      dataRef: myApp.firestore().collection("links"),
      folders: [],
      userId: this.$store.state.currentUserInfo.userId,
      errorMsg: null,
      currentfolderName: null,
      folderId: null,
      linkSize: 50,
    };
  },
  methods: {
    // Delete the data link from DB
    deleteElement(index, id) {
      SweetAlert.deleteMessage().then((result) => {
        if (result) this.dataRef.doc(id).delete();
      });
    },

    // Searching for a particular resource
    searchElement() {
      return this.datas.filter(
        (data) =>
          !this.searchItem ||
          data.content.toLowerCase().includes(this.searchItem.toLowerCase()) ||
          data.description.toLowerCase().includes(this.searchItem.toLowerCase())
      );
    },

    // Get all collections created by the user
    getAllFolders() {
      this.loaderState = true;
      let obj = {};
      this.folderRef
        .where("userId", "==", this.userId)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            this.folders = [];
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              (obj.nbDocs = null), this.folders.push(obj);
              this.getNumberOfElementsInFolder(obj, doc.id);
            });
          }
          this.loaderState = false;
        });
    },

    // This function returns the number of elements inside a folder and push all datas in an array
    getNumberOfElementsInFolder(Obj, folderId) {
      this.dataRef
        .where("userId", "==", this.userId)
        .where("folderId", "==", folderId)
        .onSnapshot((snapshot) => {
          Obj.nbDocs = snapshot.size;
          this.loaderState = false;
        });
    },

    // This func allows to get all datas in a given collection
    getAllDatasInsideFolder() {
      // Get current collection id
      this.folderId = localStorage.getItem("id_res");
      const userId = this.$store.state.currentUserInfo.userId;
      let obj = {};
      this.dataRef
        .where("userId", "==", userId)
        .where("folderId", "==", this.folderId)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          this.datas = [];
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              this.datas.push(obj);
              this.loaderState = false;
            });
          }
          this.loaderState = false;
        });
    },

    changeCollectionInfo(name, descr, ref) {
      if (ref != localStorage.getItem("id_res")) {
        localStorage.setItem("name", name);
        localStorage.setItem("descr", descr);
        localStorage.setItem("id_res", ref);
        this.$router.replace(`/view-resource/fr/${name}`);
        this.callBundleFunc();
      }
    },

    callBundleFunc() {
      this.currentfolderName = localStorage.getItem("name");
      this.folderDescr = localStorage.getItem("descr");
      this.getAllDatasInsideFolder();
      this.getAllFolders();
    },
  },
  created() {
    this.loaderState = true;
    this.searchItem = null;
    this.callBundleFunc();
    if (window.innerWidth <= 700) this.linkSize = 15;
  },
};
</script>

<style scoped>
.li-hover:hover {
  cursor: pointer;
}
.color-white {
  color: #fff;
}
.tag-style {
  color: #fff;
  background-color: #f9826c;
  border: 1px solid #f9826c;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  font-size: 13px;
}
.element-icon-style {
  width: 50px;
  height: 50px;
  box-shadow: 0 3px 6px 0 rgba(149, 157, 165, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.link-style {
  font-size: 15px;
  color: #0366d6;
}
</style>
